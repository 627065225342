/* eslint-disable react/display-name */
import hash from 'object-hash'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { point } from '@turf/helpers'
import area from '@turf/area'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import simplify from '@turf/simplify'
import api from '../../api'
import {
  extractTypeUnique,
  ID_NETWORK_COMPLAINTS, ID_NETWORK_COVERAGE, ID_NETWORK_RBS, ID_NETWORK_SECTORS, ID_NETWORK_SITES,
  ID_NETWORK_TO_DATA_TYPE, ID_NETWORK_BC, SITE_ID_FIELD, SITE_KOATOO_ALL_CITIES_FIELD, SITE_KOATOO_DISTRICTS_FIELD,
  SITE_KOATOO_MAIN_CITIES_FIELD, SITE_LAT_FIELD, SITE_LAUNCH_DATE_FIELD, SITE_LNG_FIELD, SITE_NAME_FIELD,
  SITE_POLYGON_FIELD, SITE_STATUS_FIELD, SITE_SYNC_ATOLL_STATUS_FIELD, RBS_ID_FIELD, RBS_NAME_FIELD, RBS_SITE_FIELD,
  RBS_SITE_NAME_FIELD, RBS_STATUS_FIELD, RBS_TECHNOLOGY_FIELD, SECTOR_AZIMUTH_FIELD, SECTOR_BASE, SECTOR_COLORS,
  SECTOR_DEVICE_TYPE_FIELD, SECTOR_ID_FIELD, SECTOR_LAT_FIELD, SECTOR_LAUNCH_DATE_FIELD, SECTOR_LNG_FIELD,
  SECTOR_NAME_FIELD, SECTOR_RBS_FIELD, SECTOR_RBS_NAME_FIELD, SECTOR_SITE_FIELD, SECTOR_SITE_NAME_FIELD,
  SECTOR_STATUS_FIELD, SECTOR_TECH_FIELD, SECTOR_TYPES, SECTOR_TYPES_UNIQUE, SECTOR_X_FIELD, SECTOR_Y_FIELD,
  SECTOR_SYNC_ATOLL_STATUS_FIELD, COMPLAINT_DATE_FIELD, COMPLAINT_ID_FIELD, COMPLAINT_LAT_FIELD, COMPLAINT_LNG_FIELD,
  COMPLAINT_NAME_FIELD, COMPLAINT_SECTOR_FIELD, COMPLAINT_SITE_FIELD, COMPLAINT_TYPE_P_FIELD, COMPLAINT_WEIGHT_FIELD,
  COMPLAINT_ACCIDENT_ON_BS_FIELD, DIVIDER, STATUS_REMOVED, SYNC_ATOLL_STATUS_NEVER, COMPLAINT_TYPE_S_FIELD,
  STATUS_ACTIVE,
} from '../../constants/network'
import { SPREAD_DISCRETE, SPREAD_RANGE, SPREAD_UNIQUE } from '../../constants/settings'
import { DEFAULT_COLORS } from '../../components/Map/draw'
import { findNode, invertProp, setTreeItemSelected } from '../../utils/tree'
import { binaryFindIndex } from '../../utils/math'
import { tablesToExcel, tableToExcel } from '../../utils/export'
import {
  cropByFilteringZone, loadGeoZones, selectFocusZones, setBounce, selectZoneCount, deleteZones, dropZone,
} from '../geo/geoSlice'
import {
  addStage, clearStages, setLoading, stageAborted, stageCompleted, stageFiltered, stageLoaded, stagePayload,
} from '../loading/loadingSlice'
import { activeProjectId, ensureUserProject, selectIsMyProject } from '../projects/projectsSlice'
import { DATA_TYPES } from '../../constants/common'
import { LINK_TAB_BY_DATA_TYPE } from '../../constants/link'
import { selectUserAccess } from '../login/loginSlice'
import { initVectorMaps, vectorMapCount } from '../vector/vectorSlice'
import { initRasterMaps, rasterMapCount } from '../raster/rasterSlice'
import {
  addFilters as bcAddFilters, addGroupFilters as bcAddGroupFilters,
  doCreateBusinessCases, loadAllBCData, loadDefaultBCData, prepareSiteNames, selectBCLocalFilters,
  updateCalculateForApprove,
} from '../bc/bcSlice'
import { loadProjectJobs } from '../taskLog/taskLogSlice'
import {
  getFiltersFromSettings, saveSettings,
  selectSettingsDeepFiltrationUp, selectSettingsDeepFiltrationDown,
} from '../settings/settingsSlice'
import { STAGE_KEYS } from '../loading/keys'
import { BUNDLE_TREE_LEVELS } from '../../api/network'
import { toPolygon } from '../../components/Map/utils'
import { setPanel } from '../panel/panelSlice'
import { BC_SITE_FIELD } from '../bc/constants'
import { doRequestAsyncReport } from '../reports/reportsSlice'
import { EVENT_TYPE, wrapByEventLog } from '../eventLog/eventLogSlice'
import { resetFiltersInAllTables, saveFilterLog } from '../filters/filters'
import { BUSINESS_CASES, COMPLAINTS, COVERAGE, EDIT_ELEMENTS } from '../../constants/access'
import {
  ID_GEO_ZONES_COMPUTATION, ID_GEO_ZONES_FILTERING, INDEX_ZONE_FOCUS, MAX_LEGEND_ITEMS,
} from '../../constants/geo'
import { REPORT_COVERAGE_BY_SECTOR, REPORT_COVERAGE_BY_SIGNAL } from '../../constants/reports'
import { alwaysTrue } from '../../utils/format'
import { combineFilters, cropByTableFilters } from './filtration'
import { cropByChildrenItems, cropByParentItems } from './deepFiltration'
import Data, { buildIndex, defaultSortFn, findIndex, sortFunc } from './indexing'
import { buildElementsTree, COMPLAINTS_TREE, makeGroupByMenu, SECTORS_TREE, SITES_TREE } from './menus'
import { ACTION_CREATE, ACTION_DELETE, ACTION_UPDATE } from './mutations'
import { coverageNameComparator } from './utils'

export const TOP_INDEX_SITES = 0
export const TOP_INDEX_SECTORS = 1
export const TOP_INDEX_RBS = 2
export const TOP_INDEX_COVERAGE = 3
export const TOP_INDEX_COMPLAINTS = 4

export const COVERAGE_REPORT_KEY = 'coverageReport'

const TX = 'TX'
const EXPORT_PAIN_ZONES = 'painZones'
const PAIN_ZONE = 'Pain Zone'
const READY_TO_USE = 'READY_TO_USE'
const HIDE_INIT_LOG = 5000

const initialList = {
  full: new Data(),
  list: new Data(),
  fields: [],
  redraw: 0,
  groupBy: 'none',
  filters: {},
  filtering: false,
  editor: null,
}

const initialState = {
  loading: 0,
  inUpdate: false,
  sites: {
    ...initialList,
    id: ID_NETWORK_SITES,
    name: 'Sites',
    state: {
      icon: 'InternetSharing',
      selected: localStorage.getItem(`network-layer-visible-${TOP_INDEX_SITES}`) === 'true',
    },
    path: [ TOP_INDEX_SITES ],
  },
  sectors: {
    ...initialList,
    id: ID_NETWORK_SECTORS,
    name: 'Sectors',
    state: {
      icon: 'TriangleLeft12',
      selected: localStorage.getItem(`network-layer-visible-${TOP_INDEX_SECTORS}`) === 'true',
    },
    types: SECTOR_TYPES,
    groupBy: SECTOR_TECH_FIELD,
    path: [ TOP_INDEX_SECTORS ],
  },
  baseStations: {
    ...initialList,
    id: ID_NETWORK_RBS,
    name: 'RBS',
    state: {
      icon: 'WifiEthernet',
      selectable: false,
    },
    groupBy: RBS_SITE_NAME_FIELD,
    path: [ TOP_INDEX_RBS ],
  },
  coverage: {
    id: ID_NETWORK_COVERAGE,
    name: 'Coverage',
    state: {
      icon: 'ChartSeries',
      selected: false,
    },
    path: [ TOP_INDEX_COVERAGE ],
    redraw: 0,
  },
  complaints: {
    ...initialList,
    id: ID_NETWORK_COMPLAINTS,
    name: 'Complaints',
    state: {
      icon: 'Warning',
      selected: localStorage.getItem(`network-layer-visible-${TOP_INDEX_COMPLAINTS}`) === 'true',
    },
    path: [ TOP_INDEX_COMPLAINTS ],
    historical: null,
  },
  editSiteIdx: null,
  editComplaintIdx: null,
  editPath: [],
  createSiteMode: false,
  googleMapsApiKey: null,
  reloadTC: 0,
  lastLoadedProject: null,
  bundle: null,
  networkTemplates: [],
}

const createSiteItems = [
  {
    key: '-',
  },
  {
    key: 'create-site',
    text: 'Create new Site',
  },
]

const coverageExportItems = (selected) => [
  {
    key: COVERAGE_REPORT_KEY,
    text: selected?.length > 0 ? 'Coverage by Signal level' : 'Coverage by Sectors',
    iconProps: { iconName: 'Export' },
  },
]

const getRoot = (state) => {
  const { sites, sectors, baseStations, complaints, coverage } = (state.network ? state.network : state)
  const children = [ sites, sectors, baseStations, coverage, complaints ]
  if (state.network) {
    children.push(state.bc.businessCases)
  }
  return {
    children,
  }
}

export const lists = (branch, meta = false) => meta
  ? [ branch.full, branch.list ]
  : [ branch.full.getList(), branch.list.getList() ]

const toggleSelection = (state, path) => {
  const currentRedraw = getRoot(state).children[path[0]].redraw
  invertProp(state, path, 'selected', true, getRoot, currentRedraw || 0) // !!!
  if (path.length === 1) {
    // all lines under this condition, except of localStorage.setItem, it the fix for SCAPEX-660
    const root = getRoot(state).children[path[0]]
    const selected = root.state?.selected
    localStorage.setItem(`network-layer-visible-${path[0]}`, selected)
    if (selected) {
      const full = root.full?.getList()
      full?.forEach((item) => {
        item.hidden = false
      })
    }
  }
  getRoot(state).children[path[0]].redraw++
  if (path[0] === TOP_INDEX_SECTORS) {
    state.reloadTC++
  }
}

const getConfig = createAsyncThunk(
  'network/getConfig',
  api.network.getConfig,
)

export const getComplaints = createAsyncThunk(
  'network/getComplaints',
  api.network.getComplaints,
)

export const getComplaintsForPeriod = createAsyncThunk(
  'network/getComplaintsForPeriod',
  api.network.getComplaintsForPeriod,
)

export const getSites = createAsyncThunk(
  'network/getSites',
  api.network.getSites,
)

export const getBaseStations = createAsyncThunk(
  'network/getBaseStations',
  api.network.getBaseStations,
)

export const getSectors = createAsyncThunk(
  'network/getSectors',
  api.network.getSectors,
)

export const getProjectSites = createAsyncThunk(
  'network/getProjectSites',
  api.network.getSites,
)

export const getProjectBaseStations = createAsyncThunk(
  'network/getProjectBaseStations',
  api.network.getBaseStations,
)

export const getProjectSectors = createAsyncThunk(
  'network/getProjectSectors',
  api.network.getSectors,
)

export const updateBundle = createAsyncThunk(
  'network/updateBundle',
  api.network.updateBundle,
)

export const updateBundlePrepare = createAsyncThunk(
  'network/updateBundlePrepare',
  api.network.updateBundlePrepare,
)

const siteTargetCoverage = createAsyncThunk(
  'network/getSiteTargetCoverage',
  api.network.getSiteTargetCoverage,
)

const sectorTargetCoverage = createAsyncThunk(
  'network/updateSectorTargetCoverage',
  api.network.updateSectorTargetCoverage,
)

export const getCoverage = createAsyncThunk(
  'network/getCoverage',
  api.network.getCoverage,
)

export const getNextSiteName = createAsyncThunk(
  'network/getNextSiteName',
  api.network.getNextSiteName,
)

export const getNextSectorName = createAsyncThunk(
  'network/getNextSectorName',
  api.network.getNextSectorName,
)

export const requestSites = createAsyncThunk(
  'network/requestSites',
  api.network.requestSites,
)

export const requestRBSes = createAsyncThunk(
  'network/requestRBSes',
  api.network.requestRBSes,
)

export const requestSectors = createAsyncThunk(
  'network/requestSectors',
  api.network.requestSectors,
)

export const requestSitesBySiteNames = createAsyncThunk(
  'network/requestSitesBySiteNames',
  api.network.requestSitesBySiteNames,
)

export const requestRBSesBySiteNames = createAsyncThunk(
  'network/requestRBSesBySiteNames',
  api.network.requestRBSesBySiteNames,
)

export const requestSectorsBySiteNames = createAsyncThunk(
  'network/requestSectorsBySiteNames',
  api.network.requestSectorsBySiteNames,
)

const getNetworkTemplates = createAsyncThunk(
  'network/getNetworkTemplates',
  api.network.getNetworkTemplates,
)

const clearEdit = (state) => {
  state.editSiteIdx = null
  state.editComplaintIdx = null
  state.editPath = []
}

const doSetSiteEditor = (state, id) => {
  state.editComplaintIdx = null
  state.editSiteIdx = state.sites.full.findIndexById(id)
  state.editPath = []
}

const doSetBaseStationEditor = (state, id) => {
  const baseStationIdx = state.baseStations.full.findIndexById(id)
  const siteFieldIdx = findIndex(state.baseStations.fields, RBS_SITE_FIELD)
  const element = state.baseStations.full.getList()[baseStationIdx]
  const site = element[siteFieldIdx]
  state.editComplaintIdx = null
  state.editSiteIdx = state.sites.full.findIndexById(site)
  const baseStationsRange = state.baseStations.full.findRangeByValue(site, RBS_SITE_FIELD)
  state.editPath = [ baseStationsRange.indexOf(baseStationIdx) ]
}

const doSetSectorEditor = (state, id) => {
  const sectorIdx = state.sectors.full.findIndexById(id)
  const siteFieldIdx = findIndex(state.sectors.fields, SECTOR_SITE_FIELD)
  const baseStationFieldIdx = findIndex(state.sectors.fields, SECTOR_RBS_FIELD)
  const element = state.sectors.full.getList()?.[sectorIdx]
  const site = element?.[siteFieldIdx]
  const baseStation = element?.[baseStationFieldIdx]
  if (!baseStation || !site) {
    return
  }
  const baseStationIdx = state.baseStations.full.findIndexById(baseStation)
  state.editComplaintIdx = null
  state.editSiteIdx = state.sites.full.findIndexById(site)
  const baseStationsRange = state.baseStations.full.findRangeByValue(site, RBS_SITE_FIELD)
  const sectorsRange = state.sectors.full.findRangeByValue(baseStation, SECTOR_RBS_FIELD)
  state.editPath = [
    baseStationsRange.indexOf(baseStationIdx),
    sectorsRange.indexOf(sectorIdx),
  ]
}

const doSetComplaintEditor = (state, id) => {
  const complaintIdx = state.complaints.full.findIndexById(id)
  const siteFieldIdx = findIndex(state.complaints.fields, COMPLAINT_SITE_FIELD)
  const sectorFieldIdx = findIndex(state.complaints.fields, COMPLAINT_SECTOR_FIELD)
  const baseStationFieldIdx = findIndex(state.sectors.fields, SECTOR_RBS_FIELD)
  const element = state.complaints.full.getList()[complaintIdx]
  const site = element[siteFieldIdx]
  const sector = element[sectorFieldIdx]
  const sectorIdx = sector && state.sectors.full.findIndexById(sector)
  const baseStation = state.sectors.full.getList()?.[sectorIdx]?.[baseStationFieldIdx]
  if (!site || !sector || !baseStation) {
    clearEdit(state)
    state.editComplaintIdx = complaintIdx
    return
  }
  const baseStationIdx = state.baseStations.full.findIndexById(baseStation)
  state.editComplaintIdx = null
  state.editSiteIdx = state.sites.full.findIndexById(site)
  const baseStationsRange = state.baseStations.full.findRangeByValue(site, RBS_SITE_FIELD)
  const sectorsRange = state.sectors.full.findRangeByValue(baseStation, SECTOR_RBS_FIELD)
  const complaintsRange = state.complaints.full.findRangeByValue(sector, COMPLAINT_SECTOR_FIELD)
  state.editPath = [
    baseStationsRange.indexOf(baseStationIdx),
    sectorsRange.indexOf(sectorIdx),
    complaintsRange.indexOf(complaintIdx),
  ]
}

const checkModified = (item, fields) => {
  if (!item.modified) {
    return false
  }
  const modifiedFields = item.modified
    .map((value, index) => value ? item.fields[index].id : null)
    .filter(Boolean)
  for (const field of fields) {
    if (modifiedFields.includes(field)) {
      return true
    }
  }
  return false
}

const rebuildIndexesWhenChanged = [ [ SITE_NAME_FIELD ], [ RBS_NAME_FIELD ], [ SECTOR_NAME_FIELD ] ]

const networkBranches = [ 'sites', 'baseStations', 'sectors', 'complaints' ]

const recursiveBundlePrepareUpdated = (state, items, flags, level = 0) => {
  let idx, listIdx, full, list, fullM, listM
  const keys = Object.keys(items)
  keys.forEach((key) => {
    const item = items[key]
    if (item.attributes) {
      flags[level].updateRedraw = true
      flags[level].updateList = true
      ;[ fullM, listM ] = lists(state[networkBranches[level]], true)
      ;[ full, list ] = lists(state[networkBranches[level]])
      const fields = state[networkBranches[level]].fields
      idx = fullM.findIndexById(key)
      listIdx = listM.findIndexById(key)
      if (idx == null || listIdx == null) {
        console.error('error index element', key)
        return
      }
      const attr = item.attributes
      // сборка атрибутов элемента сети
      const fieldF = [ ...full[idx] ]
      fieldF.isInMyProject = true
      const fieldL = [ ...list[listIdx] ]
      fieldL.isInMyProject = true
      const keys = Object.keys(attr)
      keys.forEach((key) => {
        const ind = findIndex(fields, key)
        if (ind == null) {
          console.error('error index attribute', key)
          return
        }
        fieldF[ind] = attr[key]
        fieldL[ind] = attr[key]
      })
      full[idx] = fieldF
      list[listIdx] = fieldL
    }
    if (item[BUNDLE_TREE_LEVELS[level + 1]]) {
      const items = item[BUNDLE_TREE_LEVELS[level + 1]]
      recursiveBundlePrepareUpdated(state, items, flags, level + 1)
    }
  })
}

const copy = (item) => {
  const element = [ ...item.element ]
  element.isInMyProject = true
  return element
}

const checkSiteNameChanged = (item) => {
  const idx = findIndex(item.fields, SITE_NAME_FIELD)
  return item.modified && item.modified[idx]
}

const updateChildrenSiteName = (state, item) => {
  const idx = findIndex(item.fields, SITE_NAME_FIELD)
  const name = item.element[idx]

  const baseStations = state.baseStations.full.findRangeByValue(item.id, RBS_SITE_FIELD)
  if (baseStations) {
    const full = state.baseStations.full.getList()
    const listMeta = state.baseStations.list
    const list = state.baseStations.list.getList()
    const rbsIdIdx = findIndex(state.baseStations.fields, RBS_ID_FIELD)
    const rbsSiteNameIdx = findIndex(state.baseStations.fields, RBS_SITE_NAME_FIELD)
    baseStations.forEach((baseStation) => {
      const element = full[baseStation]
      element[rbsSiteNameIdx] = name
      const id = element[rbsIdIdx]
      const listIdx = listMeta.findIndexById(id)
      list[listIdx][rbsSiteNameIdx] = name
    })
  }

  const sectors = state.sectors.full.findRangeByValue(item.id, SECTOR_SITE_FIELD)
  if (sectors) {
    const fullS = state.sectors.full.getList()
    const listMetaS = state.sectors.list
    const listS = state.sectors.list.getList()
    const sectorIdIdx = findIndex(state.sectors.fields, SECTOR_ID_FIELD)
    const sectorSiteNameIdx = findIndex(state.sectors.fields, SECTOR_SITE_NAME_FIELD)
    sectors.forEach((sector) => {
      const element = fullS[sector]
      element[sectorSiteNameIdx] = name
      const id = element[sectorIdIdx]
      const listIdx = listMetaS.findIndexById(id)
      listS[listIdx][sectorSiteNameIdx] = name
    })
  }
}

const recursiveBundleUpdated = (state, item, flags, level = 0) => {
  let idx, listIdx, full, list
  if (item.action || (level === 0 && item.children)) {
    const id = item.id
    ;[ full, list ] = lists(state[networkBranches[level]], true)
    idx = full.findIndexById(id)
    listIdx = list.findIndexById(id)
    ;[ full, list ] = lists(state[networkBranches[level]])
  }
  if (item.action === ACTION_CREATE) {
    full.push(copy(item))
    if (list !== full) {
      list.push(copy(item))
    }
    flags[level].updateIndexes = true
    flags[level].updateRedraw = true
  } else if (item.action === ACTION_DELETE) {
    full[idx] = copy(item)
    list[listIdx] = copy(item)
    flags[level].updateList = true
    flags[level].updateIndexes = true
    flags[level].updateRedraw = true
  } else if (item.action === ACTION_UPDATE) {
    full[idx] = copy(item)
    list[listIdx] = copy(item)
    flags[level].updateList = true
    if (checkModified(item, rebuildIndexesWhenChanged[level])) {
      flags[level].updateIndexes = true
    }
    flags[level].updateRedraw = true
    if (level === 0 && checkSiteNameChanged(item)) {
      updateChildrenSiteName(state, item)
    }
  } else if (!item.action && level === 0 && item.children) {
    full[idx].isInMyProject = true
    list[listIdx].isInMyProject = true
  }
  if (item.children) {
    for (const child of item.children) {
      recursiveBundleUpdated(state, child, flags, level + 1)
    }
  }
}

const initFlag = {
  updateList: false,
  updateIndexes: false,
  updateRedraw: false,
}

// Актуалізуємо локальні таблиці після збереження даних на сервері.
// За потреби проставляємо прапорці перемальовування елементів на карті.
const bundlePrepareUpdated = (state, action) => {
  const flags = [ { ...initFlag }, { ...initFlag }, { ...initFlag } ]
  const sites = action.payload.bundle?.sites
  recursiveBundlePrepareUpdated(state, sites, flags)
  if (flags[0].updateRedraw) {
    state.sites.redraw++
    state.reloadTC++
  }
  if (flags[2].updateRedraw) {
    state.sectors.redraw++
    state.reloadTC++
  }
  if (flags[0].updateList) {
    state.sites.list = new Data(state.sites.list.getList(), state.sites.list.getIndex())
  }
  if (flags[0].updateIndexes) {
    const [ full, list ] = lists(state.sites)
    buildSiteIndexes(state, { data: full, fields: state.sites.fields })
    buildSiteList(state, list)
  }
  if (flags[1].updateList) {
    state.baseStations.list = new Data(
      state.baseStations.list.getList(),
      state.baseStations.list.getIndex(),
      state.baseStations.list.getIndexes(),
    )
  }
  if (flags[1].updateIndexes) {
    const [ full, list ] = lists(state.baseStations)
    buildBaseStationIndexes(state, { data: full, fields: state.baseStations.fields })
    buildBaseStationList(state, list)
  }
  if (flags[2].updateList) {
    state.sectors.list = new Data(
      state.sectors.list.getList(),
      state.sectors.list.getIndex(),
      state.sectors.list.getIndexes(),
    )
  }
  if (flags[2].updateIndexes) {
    const [ full, list ] = lists(state.sectors)
    buildSectorIndexes(state, { data: full, fields: state.sectors.fields })
    buildSectorList(state, list)
  }
}

export const disassemblyBundle = (action) => {
  const elements = action?.meta?.arg?.children
  const projectId = action?.meta?.arg?.activeProjectId
  const sites = []
  const rbses = []
  const sectors = []
  const sites_ = []
  const rbses_ = []
  const sectors_ = []
  Array.isArray(elements) &&
    elements.forEach((element) => {
      const siteId = element.id
      if (element.element) {
        element.element?.isInMyProject
          ? sites.push({ siteId })
          : sites_.push({ siteId })
      }
      if (Array.isArray(element.children)) {
        element.children.forEach((element) => {
          const rbsId = element.id
          if (element.element) {
            element.element?.isInMyProject
              ? rbses.push({ siteId, rbsId })
              : rbses_.push({ siteId, rbsId })
          }
          if (Array.isArray(element.children)) {
            element.children.forEach((element) => {
              element.element?.isInMyProject
                ? sectors.push({ siteId, rbsId, sectorId: element.id })
                : sectors_.push({ siteId, rbsId, sectorId: element.id })
            })
          }
        })
      }
    })
  return { rbses, sites, sectors, projectId, rbses_, sites_, sectors_ }
}

const bundleUpdated = (state, action) => {
  const flags = [ { ...initFlag }, { ...initFlag }, { ...initFlag } ]
  for (const site of action.payload.children) {
    recursiveBundleUpdated(state, site, flags)
  }
  if (flags[0].updateRedraw) {
    state.sites.redraw++
    state.reloadTC++
  }
  if (flags[2].updateRedraw) {
    state.sectors.redraw++
    state.reloadTC++
  }
  if (flags[0].updateList) {
    state.sites.list = new Data(state.sites.list.getList(), state.sites.list.getIndex())
  }
  if (flags[0].updateIndexes) {
    const [ full, list ] = lists(state.sites)
    buildSiteIndexes(state, { data: full, fields: state.sites.fields })
    buildSiteList(state, list)
  }
  if (flags[1].updateList) {
    state.baseStations.list = new Data(
      state.baseStations.list.getList(),
      state.baseStations.list.getIndex(),
      state.baseStations.list.getIndexes(),
    )
  }
  if (flags[1].updateIndexes) {
    const [ full, list ] = lists(state.baseStations)
    buildBaseStationIndexes(state, { data: full, fields: state.baseStations.fields })
    buildBaseStationList(state, list)
  }
  if (flags[2].updateList) {
    state.sectors.list = new Data(
      state.sectors.list.getList(),
      state.sectors.list.getIndex(),
      state.sectors.list.getIndexes(),
    )
  }
  if (flags[2].updateIndexes) {
    const [ full, list ] = lists(state.sectors)
    buildSectorIndexes(state, { data: full, fields: state.sectors.fields })
    buildSectorList(state, list)
  }
}

const buildSiteIndexes = (state, bundle) => {
  state.sites.full = new Data(bundle.data, buildIndex(bundle, SITE_ID_FIELD), {
    [SITE_NAME_FIELD]: buildIndex(bundle, SITE_NAME_FIELD),
  })
}

const buildSiteList = (state, list) => {
  state.sites.list = new Data(list, buildIndex({ fields: state.sites.fields, data: list }, SITE_ID_FIELD))
  buildElementsTree(state.sites, false, ...SITES_TREE)
  state.sites.redraw++
}

const buildBaseStationIndexes = (state, bundle) => {
  state.baseStations.full = new Data(bundle.data, buildIndex(bundle, RBS_ID_FIELD), {
    [RBS_NAME_FIELD]: buildIndex(bundle, RBS_NAME_FIELD),
    [RBS_SITE_FIELD]: buildIndex(bundle, RBS_SITE_FIELD),
    [RBS_SITE_NAME_FIELD]: buildIndex(bundle, RBS_SITE_NAME_FIELD),
  })
}

const buildBaseStationList = (state, list) => {
  const bundle = { fields: state.baseStations.fields, data: list }
  state.baseStations.list = new Data(list, buildIndex(bundle, RBS_ID_FIELD), {
    [RBS_SITE_FIELD]: buildIndex(bundle, RBS_SITE_FIELD),
  })
  buildElementsTree(state.baseStations, false, ID_NETWORK_RBS, RBS_NAME_FIELD)
}

const buildSectorIndexes = (state, bundle) => {
  state.sectors.full = new Data(bundle.data, buildIndex(bundle, SECTOR_ID_FIELD), {
    [SECTOR_NAME_FIELD]: buildIndex(bundle, SECTOR_NAME_FIELD),
    [SECTOR_SITE_FIELD]: buildIndex(bundle, SECTOR_SITE_FIELD),
    [SECTOR_RBS_FIELD]: buildIndex(bundle, SECTOR_RBS_FIELD),
  })
}

const buildSectorList = (state, list) => {
  state.sectors.list = new Data(list, buildIndex({ fields: state.sectors.fields, data: list }, SECTOR_ID_FIELD), {
    [SECTOR_RBS_FIELD]: buildIndex({ fields: state.sectors.fields, data: list }, SECTOR_RBS_FIELD),
  })
  buildElementsTree(state.sectors, false, ...SECTORS_TREE)
  state.sectors.redraw++
}

const buildComplaintIndexes = (state, bundle) => {
  state.complaints.full = new Data(bundle.data, buildIndex(bundle, COMPLAINT_ID_FIELD), {
    [COMPLAINT_SITE_FIELD]: buildIndex(bundle, COMPLAINT_SITE_FIELD),
    [COMPLAINT_SECTOR_FIELD]: buildIndex(bundle, COMPLAINT_SECTOR_FIELD),
  })
}

const buildComplaintList = (state, list) => {
  state.complaints.list =
    new Data(list, buildIndex({ fields: state.complaints.fields, data: list }, COMPLAINT_ID_FIELD))
  buildElementsTree(state.complaints, false, ...COMPLAINTS_TREE)
  state.complaints.redraw++
}

const addProjectElements = (full, additionalList, idFldIdx, isDefaultProject, updateOnlyIfInList) => {
  for (const element of additionalList.data) {
    element.isInMyProject = !isDefaultProject
    const id = element[idFldIdx]
    const idx = full.findIndexById(id)
    if (idx >= 0) {
      full.getList()[idx] = element
    } else if (!updateOnlyIfInList) {
      full.getList().push(element)
    }
  }
}

const sitesLoaded = (state, action) => {
  const idIdx = findIndex(state.sites.fields, SITE_ID_FIELD)
  const defProject = action.meta?.arg?.projectId === '_'
  addProjectElements(state.sites.full, action.payload, idIdx, defProject)
  buildSiteIndexes(state, { data: state.sites.full.getList(), fields: state.sites.fields })
  addProjectElements(state.sites.list, action.payload, idIdx, defProject,
    action.type !== 'network/requestSitesBySiteNames/fulfilled')
  state.sites.list = new Data(state.sites.list.getList(), state.sites.list.getIndex())
}

const rbsesLoaded = (state, action) => {
  const idIdx = findIndex(state.baseStations.fields, RBS_ID_FIELD)
  const defProject = action.meta?.arg?.projectId === '_'
  addProjectElements(state.baseStations.full, action.payload, idIdx, defProject)
  buildBaseStationIndexes(state, { data: state.baseStations.full.getList(), fields: state.baseStations.fields })
  addProjectElements(state.baseStations.list, action.payload, idIdx, defProject,
    action.type !== 'network/requestRBSesBySiteNames/fulfilled')
  state.baseStations.list = new Data(
    state.baseStations.list.getList(),
    state.baseStations.list.getIndex(),
    state.baseStations.list.getIndexes(),
  )
}

const sectorsLoaded = (state, action) => {
  const idIdx = findIndex(state.sectors.fields, SECTOR_ID_FIELD)
  const defProject = action.meta?.arg?.projectId === '_'
  addProjectElements(state.sectors.full, action.payload, idIdx, defProject)
  buildSectorIndexes(state, { data: state.sectors.full.getList(), fields: state.sectors.fields })
  addProjectElements(state.sectors.list, action.payload, idIdx, defProject,
    action.type !== 'network/requestSectorsBySiteNames/fulfilled')
  state.sectors.list = new Data(
    state.sectors.list.getList(),
    state.sectors.list.getIndex(),
    state.sectors.list.getIndexes(),
  )
}

export const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    treeItemSelect: (state, action) => {
      setTreeItemSelected(state, action.payload, true, toggleSelection, getRoot)
    },
    treeItemUnselect: (state, action) => {
      setTreeItemSelected(state, action.payload, false, toggleSelection, getRoot)
    },
    checkTreeItem: (state, action) => {
      toggleSelection(state, action.payload)
    },
    expandTreeItem: (state, action) => {
      invertProp(state, action.payload, 'expanded', false, getRoot)
      const path = action.payload
      if (path?.length > 1) { // Инициируем срабатывание селектора на изменени состояния дочерних элементов tree
        const rootTree = getRoot(state).children[path?.[0]]
        if (rootTree?.state) {
          rootTree.state = { ...rootTree.state }
        }
      }
    },
    setSiteList: (state, action) => {
      buildSiteList(state, action.payload)
    },
    setBaseStationList: (state, action) => {
      buildBaseStationList(state, action.payload)
    },
    setSectorList: (state, action) => {
      buildSectorList(state, action.payload)
    },
    setComplaintList: (state, action) => {
      buildComplaintList(state, action.payload)
    },
    addFilters: (state, action) => {
      const { dataType, filter, reset } = action.payload
      combineFilters(state[dataType], filter, reset)
    },
    addGroupFilters: (state, action) => {
      const { dataType, filter } = action.payload
      state[dataType].localFilters = filter
    },
    setFiltering: (state, action) => {
      const { dataType, filtering } = action.payload
      if (dataType) {
        state[dataType].filtering = filtering
      }
    },
    resetFilters: (state, action) => {
      const dataType = action.payload
      if (state[dataType]?.filters && Object.keys(state[dataType]?.filters).length > 0) {
        state[dataType].filters = {}
      }
    },
    setSitesGroupBy: (state, action) => {
      if (state.sites.groupBy !== action.payload) {
        state.sites.groupBy = action.payload
        buildElementsTree(state.sites, true, ...SITES_TREE)
        state.sites.redraw++
      }
    },
    setSectorsGroupBy: (state, action) => {
      if (state.sectors.groupBy !== action.payload) {
        state.sectors.groupBy = action.payload
        buildElementsTree(state.sectors, true, ...SECTORS_TREE)
        state.sectors.redraw++
      }
    },
    setComplaintsGroupBy: (state, action) => {
      if (state.complaints.groupBy !== action.payload) {
        state.complaints.groupBy = action.payload
        buildElementsTree(state.complaints, true, ...COMPLAINTS_TREE)
      }
    },
    setBaseStationsGroupBy: (state, action) => {
      if (state.baseStations.groupBy !== action.payload) {
        state.baseStations.groupBy = action.payload
        buildElementsTree(state.baseStations, true, ID_NETWORK_RBS, RBS_NAME_FIELD)
      }
    },
    elementsRedraw: (state, action) => {
      state.sites.redraw++
      state.sectors.redraw++
      state.complaints.redraw++
    },
    setSiteEditor: (state, action) => {
      if (action.payload === null) {
        clearEdit(state)
      } else {
        doSetSiteEditor(state, action.payload)
      }
    },
    setBaseStationEditor: (state, action) => {
      if (action.payload === null) {
        clearEdit(state)
      } else {
        doSetBaseStationEditor(state, action.payload)
      }
    },
    setSectorEditor: (state, action) => {
      if (action.payload === null) {
        clearEdit(state)
      } else {
        doSetSectorEditor(state, action.payload)
      }
    },
    setComplaintEditor: (state, action) => {
      if (action.payload === null) {
        clearEdit(state)
      } else {
        doSetComplaintEditor(state, action.payload)
      }
    },
    cleanUpBundle: (state) => {
      clearEdit(state)
    },
    setBundlePath: (state, action) => {
      state.editPath = action.payload
    },
    createSiteMode: (state, action) => {
      state.createSiteMode = action.payload
    },
    setCoverage: (state, action) => {
      const list = []
      action.payload?.forEach((item) => {
        const parts = item.name.replace('Best Signal Level (dBm)', 'dBm').split('_')
        if (parts[0] === TX) {
          let index = list.findIndex((i) => i.name === TX)
          if (index === -1) {
            list.push({
              id: 'coverage-tx',
              name: TX,
              path: [ TOP_INDEX_COVERAGE, list.length ],
              children: [],
            })
            index = list.length - 1
          }
          list[index].children.push({
            ...item,
            name: parts.slice(1).join(' '),
            path: [ TOP_INDEX_COVERAGE, index, list[index].children.length ],
          })
        } else {
          const uk = parts.indexOf('Ukraine')
          const section = parts.slice(0, uk).join(' ')
          const name = parts.slice(uk).join(' ')
          let index = list.findIndex((i) => i.name === section)
          if (index < 0) {
            list.push({
              id: `coverage-${section.split(' ').join('-')}`,
              name: section,
              path: [ TOP_INDEX_COVERAGE, list.length ],
              children: [],
            })
            index = list.length - 1
          }
          list[index].children.push({
            ...item,
            name,
            path: [ TOP_INDEX_COVERAGE, index, list[index].children.length ],
          })
        }
      })
      if (list[0].children.length === 0) {
        list.shift()
      }
      list.forEach(({ children }, index) => {
        const needToSort = children.find((item) => item.name.includes('>='))
        if (needToSort) {
          children.sort(coverageNameComparator())
          children.forEach((item, itemIndex) => {
            item.path = [ TOP_INDEX_COVERAGE, index, itemIndex ]
          })
        }
      })
      state.coverage.children = list
    },
    setLastLoadedProject: (state, action) => {
      state.lastLoadedProject = action.payload
    },
    setBundle: (state, action) => {
      state.bundle = action.payload
    },
    setComplaintsHistorical: (state, action) => {
      if (action.payload) {
        const { dateFrom, dateTo } = action.payload
        state.complaints.historical = { dateFrom, dateTo }
      } else {
        state.complaints.historical = null
      }
    },
    setInUpdate: (state, action) => {
      state.inUpdate = action.payload
    },
  },
  extraReducers: (builder) => builder
    .addCase(getComplaints.fulfilled, (state, action) => {
      state.complaints.fields = action.payload.fields
      // console.info('Complaint fields', action.payload.fields)
      // console.log('Complaints count', action.payload.data.length)
      buildComplaintIndexes(state, action.payload)
    })
    .addCase(getComplaintsForPeriod.fulfilled, (state, action) => {
      // state.complaints.fields = action.payload.data.fields
      // console.info('Complaints History', action.payload.data.data.length)
      buildComplaintIndexes(state, action.payload.data)
    })
    .addCase(getSites.fulfilled, (state, action) => {
      state.sites.fields = action.payload.fields
      // console.info('Site fields', action.payload.fields)
      buildSiteIndexes(state, action.payload)
    })
    .addCase(getProjectSites.fulfilled, (state, action) => {
      addProjectElements(state.sites.full, action.payload, findIndex(state.sites.fields, SITE_ID_FIELD))
      buildSiteIndexes(state, { data: state.sites.full.getList(), fields: state.sites.fields })
    })
    .addCase(getBaseStations.fulfilled, (state, action) => {
      state.baseStations.fields = action.payload.fields
      // console.info('RBS fields', action.payload.fields)
      buildBaseStationIndexes(state, action.payload)
    })
    .addCase(getProjectBaseStations.fulfilled, (state, action) => {
      addProjectElements(state.baseStations.full, action.payload, findIndex(state.baseStations.fields, RBS_ID_FIELD))
      buildBaseStationIndexes(state, { data: state.baseStations.full.getList(), fields: state.baseStations.fields })
    })
    .addCase(getSectors.fulfilled, (state, action) => {
      state.sectors.fields = action.payload.fields.map((item) => {
        if (item.id === SECTOR_TECH_FIELD) {
          item['enum-values'] = Object.keys(SECTOR_BASE).join(',')
        }
        return item
      })
      // console.info('Sector fields', action.payload.fields)
      buildSectorIndexes(state, action.payload)
    })
    .addCase(getProjectSectors.fulfilled, (state, action) => {
      addProjectElements(state.sectors.full, action.payload, findIndex(state.sectors.fields, SECTOR_ID_FIELD))
      buildSectorIndexes(state, { data: state.sectors.full.getList(), fields: state.sectors.fields })
    })
    .addCase(updateBundle.fulfilled, bundleUpdated)
    .addCase(updateBundlePrepare.fulfilled, bundlePrepareUpdated)
    .addCase(getConfig.fulfilled, (state, action) => {
      state.googleMapsApiKey = action.payload?.['google.api.key']
      state.docUrl = action.payload?.['doc.url']
      state.passwordRules = {
        lengthMax: action.payload?.['password.length.max'],
        lengthMin: action.payload?.['password.length.min'],
        lowerCase: action.payload?.['password.lowercase.min'],
        number: action.payload?.['password.number.min'],
        special: action.payload?.['password.special.min'],
        upperCase: action.payload?.['password.uppercase.min'],
      }
      // console.info('config', action.payload)
      const eventId = Number(action.payload?.eventId)
      const savedEventId = Number(localStorage.getItem('lastNotificationId'))
      if (!isNaN(eventId) && eventId >= 0 && (isNaN(savedEventId) || savedEventId === 0 || eventId < savedEventId)) {
        // console.info('Downgrade lastNotificationId', { eventId, savedEventId })
        localStorage.setItem('lastNotificationId', eventId.toString())
      }
    })
    .addCase(requestSites.fulfilled, sitesLoaded)
    .addCase(requestRBSes.fulfilled, rbsesLoaded)
    .addCase(requestSectors.fulfilled, sectorsLoaded)
    .addCase(requestSitesBySiteNames.fulfilled, sitesLoaded)
    .addCase(requestRBSesBySiteNames.fulfilled, rbsesLoaded)
    .addCase(requestSectorsBySiteNames.fulfilled, sectorsLoaded)
    .addCase(doCreateBusinessCases.fulfilled, (state, action) => {
      const names = action.meta.arg.businessCases?.map((item) => item[BC_SITE_FIELD]) || []
      const siteIdIdx = findIndex(state.sites.fields, SITE_ID_FIELD)
      const rbsIdIdx = findIndex(state.baseStations.fields, RBS_ID_FIELD)
      names.forEach((name) => {
        let index = state.sites.full.findRangeByValue(name, SITE_NAME_FIELD)?.[0] || -1
        if (index >= 0) {
          const site = state.sites.full.getList()[index]
          if (site && !site.isInMyProject) {
            site.isInMyProject = true
            const rbsIndexes = state.baseStations.full.findRangeByValue(site[siteIdIdx], RBS_SITE_FIELD)
            rbsIndexes?.forEach((rbsIndex) => {
              const rbs = state.baseStations.full.getList()[rbsIndex]
              rbs.isInMyProject = true
              const sectorIndexes = state.sectors.full.findRangeByValue(rbs[rbsIdIdx], SECTOR_RBS_FIELD)
              sectorIndexes?.forEach((sectorIndex) => {
                state.sectors.full.getList()[sectorIndex].isInMyProject = true
              })
            })
          }
        }
        index = state.sites.list.findRangeByValue(name, SITE_NAME_FIELD)?.[0] || -1
        if (index >= 0) {
          const site = state.sites.list.getList()[index]
          if (site && !site.isInMyProject) {
            site.isInMyProject = true
            const rbsIndexes = state.baseStations.list.findRangeByValue(site[siteIdIdx], RBS_SITE_FIELD)
            rbsIndexes?.forEach((rbsIndex) => {
              const rbs = state.baseStations.list.getList()[rbsIndex]
              rbs.isInMyProject = true
              const sectorIndexes = state.sectors.list.findRangeByValue(rbs[rbsIdIdx], SECTOR_RBS_FIELD)
              sectorIndexes?.forEach((sectorIndex) => {
                state.sectors.list.getList()[sectorIndex].isInMyProject = true
              })
            })
          }
        }
      })
    })
    .addCase(getNetworkTemplates.fulfilled, (state, action) => {
      state.networkTemplates = action.payload
    })

    .addMatcher((action) => action.type.endsWith('/pending'), (state) => {
      state.loading += 1
    })
    .addMatcher((action) => action.type.endsWith('/fulfilled'), (state) => {
      state.loading -= 1
    })
    .addMatcher((action) => action.type.endsWith('/rejected'), (state, action) => {
      state.loading -= 1
      console.error(`Action [${action.type}] failed with error: ${action.error?.message}`)
    }),
})

export const selectPasswordRules = (state) => state.network.passwordRules
export const selectComplaintsContainer = (state) => state.network.complaints
export const selectComplaints = (state) => state.network.complaints.list
export const selectCountComplaints = (state) => state.network.complaints.list?.getList().length
export const selectComplaintsFull = (state) => state.network.complaints.full
export const selectCountComplaintsFull = (state) => state.network.complaints.full?.getList().length
export const selectComplaintFields = (state) => state.network.complaints.fields
export const selectComplaintsRedraw = (state) => state.network.complaints.redraw
export const selectComplaintsFilters = (state) => state.network.complaints.filters
export const selectComplaintsStationsLocalFilters = (state) => state.network.complaints.localFilters
// export const selectComplaintsState = (state) => state.network.complaints.state
// export const selectComplaintsChildren = (state) => state.network.complaints.children

export const selectSitesContainer = (state) => state.network.sites
export const selectSites = (state) => state.network.sites.list
export const selectCountSites = (state) => state.network.sites.list.getList().length
export const selectSitesFull = (state) => state.network.sites.full
export const selectCountSitesFull = (state) => state.network.sites.full?.getList()?.length
export const selectSiteFields = (state) => state.network.sites.fields
export const selectSitesRedraw = (state) => state.network.sites.redraw
export const selectSitesFilters = (state) => state.network.sites.filters
export const selectSitesLocalFilters = (state) => state.network.sites.localFilters

export const selectBaseStationsContainer = (state) => state.network.baseStations
export const selectBaseStations = (state) => state.network.baseStations.list
export const selectCountBaseStations = (state) => state.network.baseStations.list.getList().length
export const selectBaseStationsFull = (state) => state.network.baseStations.full
export const selectCountBaseStationsFull = (state) => state.network.baseStations.full?.getList().length
export const selectBaseStationFields = (state) => state.network.baseStations.fields
export const selectBaseStationsFilters = (state) => state.network.baseStations.filters
export const selectBaseStationsLocalFilters = (state) => state.network.baseStations.localFilters

export const selectSectorsContainer = (state) => state.network.sectors
export const selectSectors = (state) => state.network.sectors.list
export const selectCountSectors = (state) => state.network.sectors.list.getList().length
export const selectSectorsFull = (state) => state.network.sectors.full
export const selectCountSectorsFull = (state) => state.network.sectors.full?.getList().length
export const selectSectorFields = (state) => state.network.sectors.fields
export const selectSectorTypes = (state) => state.network.sectors.types
export const selectSectorsRedraw = (state) => state.network.sectors.redraw
export const selectSectorsFilters = (state) => state.network.sectors.filters
export const selectSectorsLocalFilters = (state) => state.network.sectors.localFilters

export const selectEditActive = (state) => state.network.editSiteIdx !== null || state.network.editComplaintIdx !== null
export const selectEditPath = (state) => state.network.editPath
export const selectCreateSiteMode = (state) => state.network.createSiteMode
export const selectApiKey = (state) => state.network.googleMapsApiKey
export const selectReloadTC = (state) => state.network.reloadTC
export const selectLastLoadedProject = (state) => state.network.lastLoadedProject
export const selectDocUrl = (state) => state.network.docUrl
export const selectNetworkTemplates = (state) => state.network.networkTemplates

export const selectIsLoading = (state) => state.network.loading > 0
export const selectInUpdate = (state) => state.network.inUpdate

export const selectCurrentNetworkTemplate = (state) => {
  const { networkTemplates } = state.network
  const { networkTemplate } = state.settings
  return networkTemplates.find((item) => item.name === networkTemplate)
}

export const selectBundle = (state) => state.network.bundle

export const selectGroupFilters = (dataType) => (state) => {
  switch (dataType) {
    case DATA_TYPES.BASE_STATIONS: return selectBaseStationsLocalFilters(state)
    case DATA_TYPES.COMPLAINTS: return selectComplaintsStationsLocalFilters(state)
    case DATA_TYPES.SECTORS: return selectSectorsLocalFilters(state)
    case DATA_TYPES.SITES: return selectSitesLocalFilters(state)
    case DATA_TYPES.BUSINESS_CASES: return selectBCLocalFilters(state)
    default:
  }
}

const checkAddCoverageItem = (list, item, tx = false, parent) => {
  if (item) {
    const { id, name } = item
    if (item.state?.selected && item.id.indexOf('coverage') < 0) {
      list.push({ id, name, tx, parent, order: item.state?.order })
    }

    item.children?.forEach((child) => {
      checkAddCoverageItem(list, child, item.name === TX, item.id)
    })
  }
}

export const selectSelectedCoverage = (state) => {
  const result = []
  checkAddCoverageItem(result, state.network.coverage)
  return result
}

export const selectDefaultCoverageMapIds = (state) => {
  const txNode = state.network.coverage?.children.find((node) => node.name === TX)
  return txNode?.children?.map((node) => node.id) || []
}

export const formSiteBundle = (state, siteIdx, makeCopyForEdit = false, skipRemoved = false) => {
  const fullComplaints = selectComplaintsFull(state)
  const [ complaintIdIdx, complaintNameIdx ] = selectComplaintFldIdx(state)
  const fullSectors = selectSectorsFull(state)
  const [ sectorIdIdx, sectorNameIdx,,,,,,, sectorStatusIdx ] = selectSectorFldIdx(state)
  const fullBaseStations = selectBaseStationsFull(state)
  const [ baseStationIdIdx, baseStationNameIdx,, baseStationStatusIdx ] = selectBaseStationFldIdx(state)
  const fullSites = selectSitesFull(state)
  const [ siteIdIdx, siteNameIdx ] = selectSiteFldIdx(state)

  const site = fullSites.getList()[siteIdx]

  const baseStationsRange = fullBaseStations.findRangeByValue(site[siteIdIdx], RBS_SITE_FIELD)
  const baseStations = baseStationsRange
    ? baseStationsRange.map((index, i) => ({
      element: makeCopyForEdit ? [ ...fullBaseStations.getList()[index] ] : fullBaseStations.getList()[index],
      origin: [ ...fullBaseStations.getList()[index] ],
      id: fullBaseStations.getList()[index][baseStationIdIdx],
      name: fullBaseStations.getList()[index][baseStationNameIdx],
      type: 'baseStation',
      fields: state.network.baseStations.fields,
      path: [ i ],
    })).filter((item) => !skipRemoved || item.element[baseStationStatusIdx] !== STATUS_REMOVED)
    : []

  baseStations.forEach((baseStation) => {
    const sectorsRange = fullSectors.findRangeByValue(baseStation.element[baseStationIdIdx], SECTOR_RBS_FIELD)
    const sectors = sectorsRange
      ? sectorsRange.map((index, i) => ({
        element: makeCopyForEdit ? [ ...fullSectors.getList()[index] ] : fullSectors.getList()[index],
        origin: [ ...fullSectors.getList()[index] ],
        id: fullSectors.getList()[index][sectorIdIdx],
        name: fullSectors.getList()[index][sectorNameIdx],
        type: 'sector',
        fields: state.network.sectors.fields,
        path: [ ...baseStation.path, i ],
      })).filter((item) => !skipRemoved || item.element[sectorStatusIdx] !== STATUS_REMOVED)
      : []
    baseStation.children = sectors

    sectors.forEach((sector) => {
      const complaintsRange = fullComplaints.findRangeByValue(sector.element[sectorIdIdx], COMPLAINT_SECTOR_FIELD)
      const complaints = complaintsRange
        ? complaintsRange.map((index, i) => ({
          element: makeCopyForEdit ? [ ...fullComplaints.getList()[index] ] : fullComplaints.getList()[index],
          origin: [ ...fullComplaints.getList()[index] ],
          id: fullComplaints.getList()[index][complaintIdIdx],
          name: fullComplaints.getList()[index][complaintNameIdx],
          type: 'complaint',
          fields: state.network.complaints.fields,
          path: [ ...sector.path, i ],
        }))
        : []
      sector.complaints = complaints
      sector.children = complaints
    })

    baseStation.complaints = sectors.reduce((acc, sector) => acc.concat(sector.complaints), [])
  })

  return {
    element: makeCopyForEdit ? [ ...site ] : site,
    origin: [ ...site ],
    id: site[siteIdIdx],
    name: site[siteNameIdx],
    type: 'site',
    fields: state.network.sites.fields,
    path: [],
    children: baseStations,
    complaints: baseStations.reduce((acc, baseStation) => acc.concat(baseStation.complaints), []),
  }
}

export const selectEditBundle = (makeCopyForEdit = false) => (state) => {
  const editSiteIdx = state.network.editSiteIdx
  if (editSiteIdx >= state.network.sites.full.getList().length) {
    return null
  }
  if (editSiteIdx === null || editSiteIdx === undefined) {
    const editComplaintIdx = state.network.editComplaintIdx
    if (editComplaintIdx === null || editComplaintIdx === undefined) {
      return null
    }

    const fullComplaints = selectComplaintsFull(state)
    const [ , complaintNameIdx ] = selectComplaintFldIdx(state)
    const element = fullComplaints.getList()[editComplaintIdx]

    return {
      element: makeCopyForEdit ? [ ...element ] : element,
      name: element[complaintNameIdx],
      type: 'complaint',
      fields: state.network.complaints.fields,
      path: [],
    }
  } else {
    return formSiteBundle(state, editSiteIdx, makeCopyForEdit)
  }
}

export const selectNetwork = (state) => state.network

export const selectSiteFldIdx = (state) => {
  const fields = selectSiteFields(state)
  return [
    findIndex(fields, SITE_ID_FIELD),
    findIndex(fields, SITE_NAME_FIELD),
    findIndex(fields, SITE_LNG_FIELD),
    findIndex(fields, SITE_LAT_FIELD),
    findIndex(fields, SITE_STATUS_FIELD),
    findIndex(fields, SITE_LAUNCH_DATE_FIELD),
    findIndex(fields, SITE_KOATOO_ALL_CITIES_FIELD),
    findIndex(fields, SITE_KOATOO_MAIN_CITIES_FIELD),
    findIndex(fields, SITE_KOATOO_DISTRICTS_FIELD),
    findIndex(fields, SITE_POLYGON_FIELD),
  ]
}

export const selectBaseStationFldIdx = (state) => {
  const fields = selectBaseStationFields(state)
  return [
    findIndex(fields, RBS_ID_FIELD),
    findIndex(fields, RBS_NAME_FIELD),
    findIndex(fields, RBS_SITE_FIELD),
    findIndex(fields, RBS_STATUS_FIELD),
    findIndex(fields, RBS_SITE_NAME_FIELD),
    findIndex(fields, RBS_TECHNOLOGY_FIELD),
  ]
}

export const selectSectorFldIdx = (state) => {
  const fields = selectSectorFields(state)
  return [
    findIndex(fields, SECTOR_ID_FIELD),
    findIndex(fields, SECTOR_NAME_FIELD),
    findIndex(fields, SECTOR_LNG_FIELD),
    findIndex(fields, SECTOR_LAT_FIELD),
    findIndex(fields, SECTOR_AZIMUTH_FIELD),
    findIndex(fields, SECTOR_X_FIELD),
    findIndex(fields, SECTOR_Y_FIELD),
    findIndex(fields, SECTOR_TECH_FIELD),
    findIndex(fields, SECTOR_STATUS_FIELD),
    findIndex(fields, SECTOR_RBS_FIELD),
    findIndex(fields, SECTOR_RBS_NAME_FIELD),
    findIndex(fields, SECTOR_SITE_FIELD),
    findIndex(fields, SECTOR_SITE_NAME_FIELD),
    findIndex(fields, SECTOR_LAUNCH_DATE_FIELD),
    findIndex(fields, SECTOR_DEVICE_TYPE_FIELD),
  ]
}

export const selectSectorFldIdxTC = (state) => {
  const fields = selectSectorFields(state)
  return [
    findIndex(fields, SECTOR_ID_FIELD),
    findIndex(fields, SECTOR_TECH_FIELD),
  ]
}

export const selectComplaintFldIdx = (state) => {
  const fields = selectComplaintFields(state)
  return [
    findIndex(fields, COMPLAINT_ID_FIELD),
    findIndex(fields, COMPLAINT_NAME_FIELD),
    findIndex(fields, COMPLAINT_LNG_FIELD),
    findIndex(fields, COMPLAINT_LAT_FIELD),
    findIndex(fields, COMPLAINT_TYPE_P_FIELD),
    findIndex(fields, COMPLAINT_DATE_FIELD),
    findIndex(fields, COMPLAINT_WEIGHT_FIELD),
    findIndex(fields, COMPLAINT_ACCIDENT_ON_BS_FIELD),
    findIndex(fields, COMPLAINT_TYPE_S_FIELD),
  ]
}

export const selectNetworkLayersVisibility = (state) => {
  const {
    sites: {
      state: { selected: sitesVisibility },
      children: siteTypes,
      groupBy: siteGroupBy,
      fields: siteFields,
    },
    sectors: {
      state: { selected: sectorsVisibility },
      children: sectorTypes,
      groupBy: sectorGroupBy,
      fields: sectorFields,
    },
    complaints: {
      state: { selected: complaintsVisibility },
      children: complaintTypes,
      groupBy: complaintGroupBy,
      fields: complaintFields,
    },
  } = state.network

  let siteTypeIdx = -1
  let sectorTypeIdx = -1
  let complaintTypeIdx = -1
  let siteSortFn = defaultSortFn
  let sectorSortFn = defaultSortFn
  let complaintSortFn = defaultSortFn

  if (siteGroupBy !== 'none') {
    siteTypeIdx = findIndex(siteFields, siteGroupBy)
    siteSortFn = sortFunc(siteGroupBy, siteFields[siteTypeIdx]?.type)
  }

  if (sectorGroupBy !== 'none') {
    sectorTypeIdx = findIndex(sectorFields, sectorGroupBy)
    sectorSortFn = sortFunc(sectorGroupBy, sectorFields[sectorTypeIdx]?.type)
  }

  if (complaintGroupBy !== 'none') {
    complaintTypeIdx = findIndex(complaintFields, complaintGroupBy)
    complaintSortFn = sortFunc(complaintGroupBy, complaintFields[complaintTypeIdx]?.type)
  }

  if (!siteSortFn) {
    siteSortFn = defaultSortFn
  }
  if (!sectorSortFn) {
    sectorSortFn = defaultSortFn
  }
  if (!complaintSortFn) {
    complaintSortFn = defaultSortFn
  }

  return {
    sitesVisibility: !!sitesVisibility,
    sectorsVisibility: !!sectorsVisibility,
    complaintsVisibility: !!complaintsVisibility,
    siteTypesVisibility: siteTypes,
    sectorTypesVisibility: sectorTypes,
    complaintTypesVisibility: complaintTypes,
    siteTypeIdx,
    sectorTypeIdx,
    complaintTypeIdx,
    siteSortFn,
    sectorSortFn,
    complaintSortFn,
  }
}

export const selectFilteredComplaints = (state) => {
  const { complaints } = state.network
  const fields = selectComplaintFields(state)
  let typeIdx = -1
  let sortFn
  if (complaints.groupBy !== 'none') {
    typeIdx = findIndex(fields, complaints.groupBy)
    sortFn = sortFunc(complaints.groupBy, fields[typeIdx]?.type)
  }
  if (!sortFn) {
    sortFn = defaultSortFn
  }
  const [ idIdx,, lngIdx, latIdx ] = selectComplaintFldIdx(state)
  return complaints.list.getList()
    .filter((item) => {
      let result = item[idIdx] && item[latIdx] && item[lngIdx] && !item.hidden
      if (result && typeIdx >= 0) {
        const idx = binaryFindIndex(complaints.children.getList(), item[typeIdx], sortFn)
        result = complaints.children.getList()[idx]?.state?.selected
      }
      return result
    })
}

export const selectComplaintsAsGeoJSON = (state) => {
  const filteredComplaints = selectFilteredComplaints(state)
  const [
    idIdx, nameIdx, lngIdx, latIdx, typePIdx, dateIdx, weightIdx, accidentsIdx, typeSIdx,
  ] = selectComplaintFldIdx(state)
  return {
    type: 'FeatureCollection',
    features: filteredComplaints.map((item) => ({
      type: 'Feature',
      properties: {
        id: item[idIdx],
        name: item[nameIdx],
        type: item[typePIdx],
        date: item[dateIdx],
        weight: item[weightIdx],
        accidents: item[accidentsIdx],
        typeS: item[typeSIdx],
      },
      geometry: {
        type: 'Point',
        coordinates: [ item[lngIdx], item[latIdx] ],
      },
    })),
  }
}

const {
  treeItemSelect, treeItemUnselect, setSiteList, setSectorList, setComplaintList, setBaseStationList, setSitesGroupBy,
  setSectorsGroupBy, setComplaintsGroupBy, setBaseStationsGroupBy, setCoverage, setComplaintsHistorical,
} = networkSlice.actions

export const {
  checkTreeItem, expandTreeItem, elementsRedraw, addFilters, addGroupFilters, resetFilters, setSiteEditor,
  setSectorEditor, setComplaintEditor, setBaseStationEditor, cleanUpBundle, setBundlePath, createSiteMode,
  setLastLoadedProject, // setBundle, setFiltering,
  setInUpdate,
} = networkSlice.actions

export const selectNetworkTree = (state) => getRoot(state)?.children
  .map((section) => ({
    ...section,
    children: section.children,
    menu: section?.path?.[0] === TOP_INDEX_COVERAGE
      ? coverageExportItems(selectSelectedCoverage(state))
      : section.fields && section.id !== ID_NETWORK_BC && makeGroupByMenu(
        section.fields,
        section.groupBy,
        [],
        section.id === ID_NETWORK_SITES && selectUserAccess(state)[EDIT_ELEMENTS] && selectIsMyProject(state)
          ? createSiteItems
          : [],
      ),
  }))

export const selectHasNetworkFilters = (state) => {
  const zoneFilteringFound =
    state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_FILTERING)?.zone?.length > 0
  const zoneComputationFound =
    state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_COMPUTATION)?.zone?.length > 0

  const sitesFilters = selectSitesFilters(state)?.comparisonRules?.length > 0
  const rbsFilters = selectBaseStationsFilters(state)?.comparisonRules?.length > 0
  const sectorsFilters = selectSectorsFilters(state)?.comparisonRules?.length > 0
  const complaintsFilters = selectComplaintsFilters(state)?.comparisonRules?.length > 0

  return zoneFilteringFound || zoneComputationFound || sitesFilters || rbsFilters || sectorsFilters || complaintsFilters
}

export const resetGroupFilters = (dataType) => (dispatch) => {
  switch (dataType) {
    case DATA_TYPES.COMPLAINTS:
    case DATA_TYPES.SECTORS:
    case DATA_TYPES.SITES:
    case DATA_TYPES.BASE_STATIONS:
      dispatch(addGroupFilters({ dataType, filter: null }))
      break
    case DATA_TYPES.BUSINESS_CASES:
      dispatch(bcAddGroupFilters({ dataType, filter: null }))
      break
    default:
  }
}

export const generateNextSiteName = () => async (dispatch) => {
  const projectId = await dispatch(ensureUserProject())
  if (projectId === '_') {
    // dispatch(forceProjectReload())
    // return
    throw new Error('User project not found')
  }
  const result = await dispatch(getNextSiteName(projectId))
  return result.payload?.name
}

export const generateNextSectorName = (siteName) => async (dispatch, getState) => {
  const projectId = await dispatch(ensureUserProject())
  if (projectId === '_') {
    // dispatch(forceProjectReload())
    return
  }
  const result = await dispatch(getNextSectorName({ activeProjectId: projectId, siteName }))
  return result.payload?.name
}

export const activateTreeItem = (path, history) => async (dispatch, getState) => {
  const state = getState()
  const root = getRoot(state)
  const node = findNode(root, path)
  if (!node?.item) {
    const table = findNode(root, [ path[0] ])
    const dataType = ID_NETWORK_TO_DATA_TYPE[table.id]
    switch (dataType) {
      case DATA_TYPES.COMPLAINTS:
      case DATA_TYPES.SECTORS:
      case DATA_TYPES.SITES:
      case DATA_TYPES.BASE_STATIONS:
        dispatch(addGroupFilters({ dataType, filter: { columnId: table.groupBy, value: node.name } }))
        break
      case DATA_TYPES.BUSINESS_CASES:
        dispatch(bcAddGroupFilters({ dataType, filter: { columnId: table.groupBy, value: node.name } }))
        break
      default: return
    }
    const projectId = activeProjectId(state)
    const linkTab = LINK_TAB_BY_DATA_TYPE[dataType]
    linkTab && history.push(`/${projectId ?? '_'}/${linkTab}`)
    return
  }
  switch (root.children[path[0]].id) {
    case ID_NETWORK_SITES: {
      const [ siteIdIdx ] = selectSiteFldIdx(state)
      await dispatch(setSiteEditor(node.item[siteIdIdx]))
      dispatch(setPanel('edit-site'))
      break
    }
    case ID_NETWORK_SECTORS: {
      const [ sectorIdIdx ] = selectSectorFldIdx(state)
      await dispatch(setSectorEditor(node.item[sectorIdIdx]))
      dispatch(setPanel('edit-sector'))
      break
    }
    case ID_NETWORK_RBS: {
      const [ baseStationIdIdx ] = selectBaseStationFldIdx(state)
      await dispatch(setBaseStationEditor(node.item[baseStationIdIdx]))
      dispatch(setPanel('edit-rbs'))
      break
    }
    case ID_NETWORK_COMPLAINTS: {
      const [ complaintIdIdx ] = selectComplaintFldIdx(state)
      await dispatch(setComplaintEditor(node.item[complaintIdIdx]))
      dispatch(setPanel('edit-complaint'))
      break
    }
    default:
  }
}

export const getSiteNamesByIds = (siteIds) => (dispatch, getState) => {
  const state = getState()
  const sitesFull = selectSitesFull(state)
  const [ , siteNameFldIdx ] = selectSiteFldIdx(state)

  return siteIds
    .map((siteId) => {
      const idx = sitesFull.findIndexById(siteId)
      if (idx >= 0) {
        return sitesFull.getList()[idx][siteNameFldIdx]
      }
      return null
    })
    .filter(Boolean)
}

export const savePainZonesXLS = (clusterData) => (dispatch, getState) => {
  // Підготуємо посилання на таблицю скарг і її поля
  const currentState = getState()
  const fields = selectComplaintFields(currentState)
  const full = selectComplaintsFull(currentState)
  const list = full.getList()

  const data = []
  const types = []

  // Ініціалізуємо підрахунок кількості скарг за типами, обчислюємо площі буферизованих зон кластерів
  clusterData.forEach((cluster) => {
    cluster.byType = {}
    cluster.area = Math.round(area(cluster.polygon) / 1e3) / 1e3
  })

  // Формуємо дані листка скарг
  clusterData.forEach((cluster, index) => {
    cluster.features.forEach(({ properties: { id, typeS } }) => {
      const idx = full.findIndexById(id)
      const complaint = list[idx].filter((_, idx) => !fields[idx].hidden)
      data.push([ `${PAIN_ZONE} ${index + 1}`, ...complaint ])
      if (!types.includes(typeS)) {
        types.push(typeS)
      }
      cluster.byType[typeS] = (cluster.byType[typeS] || 0) + 1
    })
  })

  // Формуємо заголовки листка скарг
  const headers = [ PAIN_ZONE, ...fields.filter(({ hidden }) => !hidden).map((field) => field.label) ]

  // Формуємо листок статистики
  const headers2 = [ PAIN_ZONE, 'Area, km²', 'Complaints count', 'Complaints weight', 'Accident number', ...types ]
  const data2 = clusterData.map(({ complaints, accidents, byType, weight, area }, index) =>
    [ `${PAIN_ZONE} ${index + 1}`, area, complaints, weight, accidents, ...types.map((type) => byType[type] || 0) ])

  // Вивантажуємо два листки в книгу Excel
  tablesToExcel([
    [ headers, data, 'Complaints' ],
    [ headers2, data2, 'Statistics' ],
  ], `${EXPORT_PAIN_ZONES}.xlsx`)
}

export const selectSitesInComputation = (state) => {
  const list = state.network.sites.list.getList()
  const [ ,, lngFldIdx, latFldIdx, statusFldIdx ] = selectSiteFldIdx(state)
  const filtered = cropByFilteringZone(state, list, latFldIdx, lngFldIdx, ID_GEO_ZONES_COMPUTATION)
  return filtered
    .filter((item) => item[lngFldIdx] && item[latFldIdx] && item[statusFldIdx] === STATUS_ACTIVE)
    .map((item) => [ item[lngFldIdx], item[latFldIdx] ])
}

export const filterSites = (zoneChanged = false, isInitialization = false) => (dispatch, getState) => {
  let currentState = getState()
  const [ , , lngIdx, latIdx ] = selectSiteFldIdx(currentState)
  const full = currentState.network.sites.full
  if (zoneChanged || !full.getCache()) {
    full.setCache(cropByFilteringZone(currentState, full.getList(), latIdx, lngIdx))
  }
  if (isInitialization) {
    const filter = dispatch(getFiltersFromSettings(currentState.network.sites, DATA_TYPES.SITES))
    dispatch(addFilters({ dataType: DATA_TYPES.SITES, filter, reset: true }))
    currentState = getState()
  }
  const filteredItems = full.getCache()
  dispatch(setSiteList(cropByTableFilters(currentState.network.sites, filteredItems)))
  dispatch(saveFilterLog(DATA_TYPES.SITES, selectSitesContainer))
  return full.getList().length
}

export const filterSitesUp = () => (dispatch, getState) => {
  const currentState = getState()
  const deepFiltrationUp = selectSettingsDeepFiltrationUp(currentState)
  if (deepFiltrationUp) {
    const [ siteIdIdx ] = selectSiteFldIdx(currentState)
    const list = currentState.network.sites.list.getList()
    const childrenList = currentState.network.baseStations.list
    dispatch(setSiteList(cropByChildrenItems(list, siteIdIdx, childrenList, RBS_SITE_FIELD)))
  }
}

export const filterBaseStations = (zoneChanged = false, isInitialization = false) => (dispatch, getState) => {
  let currentState = getState()
  const full = currentState.network.baseStations.full
  if (zoneChanged || !full.getCache()) {
    // TODO: cropByFilteringZone (now we don't have [lng, lat] in BaseStations dataset)
    // cropByFilteringZone(currentState, full.getList(), latIdx, lngIdx)
    full.setCache(full.getList())
  }
  if (isInitialization) {
    const filter = dispatch(getFiltersFromSettings(currentState.network.baseStations, DATA_TYPES.BASE_STATIONS))
    dispatch(addFilters({ dataType: DATA_TYPES.BASE_STATIONS, filter, reset: true }))
    currentState = getState()
  }
  const deepFiltrationDown = selectSettingsDeepFiltrationDown(currentState)
  let filteredItems = full.getCache()
  if (deepFiltrationDown) {
    const [ , , siteIdIdx ] = selectBaseStationFldIdx(currentState)
    const parentList = currentState.network.sites.list
    filteredItems = cropByParentItems(full.getList(), siteIdIdx, parentList)
  }
  dispatch(setBaseStationList(cropByTableFilters(currentState.network.baseStations, filteredItems)))
  dispatch(saveFilterLog(DATA_TYPES.BASE_STATIONS, selectBaseStationsContainer))
  return full.getList().length
}

export const filterBaseStationsUp = () => (dispatch, getState) => {
  const currentState = getState()
  const deepFiltrationUp = selectSettingsDeepFiltrationUp(currentState)
  if (deepFiltrationUp) {
    const [ rbsIdIdx ] = selectBaseStationFldIdx(currentState)
    const list = currentState.network.baseStations.list.getList()
    const childrenList = currentState.network.sectors.list
    dispatch(setBaseStationList(cropByChildrenItems(list, rbsIdIdx, childrenList, SECTOR_RBS_FIELD)))
  }
}

export const filterSectors = (zoneChanged = false, isInitialization = false) => (dispatch, getState) => {
  let currentState = getState()
  const [ , , lngIdx, latIdx, ,,,,, rbsIdIdx ] = selectSectorFldIdx(currentState)
  const full = currentState.network.sectors.full
  if (zoneChanged || !full.getCache()) {
    full.setCache(cropByFilteringZone(currentState, full.getList(), latIdx, lngIdx))
  }
  if (isInitialization) {
    const filter = dispatch(getFiltersFromSettings(currentState.network.sectors, DATA_TYPES.SECTORS))
    dispatch(addFilters({ dataType: DATA_TYPES.SECTORS, filter, reset: true }))
    currentState = getState()
  }
  let filteredItems = full.getCache()
  const deepFiltrationDown = selectSettingsDeepFiltrationDown(currentState)
  if (deepFiltrationDown) {
    const parentList = currentState.network.baseStations.list
    filteredItems = cropByParentItems(full.getList(), rbsIdIdx, parentList)
  }
  dispatch(setSectorList(cropByTableFilters(currentState.network.sectors, filteredItems)))
  dispatch(saveFilterLog(DATA_TYPES.SECTORS, selectSectorsContainer))
  return full.getList().length
}

export const filterComplaints = (zoneChanged = false, isInitialization = false) => (dispatch, getState) => {
  let currentState = getState()
  const [ , , lngIdx, latIdx ] = selectComplaintFldIdx(currentState)
  const full = currentState.network.complaints.full
  if (zoneChanged || !full.getCache()) {
    full.setCache(cropByFilteringZone(currentState, full.getList(), latIdx, lngIdx))
  }
  if (isInitialization) {
    const filter = dispatch(getFiltersFromSettings(currentState.network.complaints, DATA_TYPES.COMPLAINTS))
    dispatch(addFilters({ dataType: DATA_TYPES.COMPLAINTS, filter, reset: true }))
    currentState = getState()
  }
  dispatch(setComplaintList(cropByTableFilters(currentState.network.complaints, full.getCache())))
  dispatch(saveFilterLog(DATA_TYPES.COMPLAINTS, selectComplaintsContainer))
  return full.getList().length
}

const bcCount = () => (dispatch, getState) => {
  let currentState = getState()
  const filter = dispatch(getFiltersFromSettings(currentState.bc.businessCases, DATA_TYPES.BUSINESS_CASES))
  dispatch(bcAddFilters({ dataType: DATA_TYPES.BUSINESS_CASES, filter, reset: true }))
  currentState = getState()
  return currentState.bc.businessCases.full.getList().length
}

export const filterNetwork = () => (dispatch, getState) => {
  dispatch(filterSites(true))
  dispatch(filterBaseStations(true))
  dispatch(filterSectors(true))
  if (selectUserAccess(getState())[COMPLAINTS]) {
    dispatch(filterComplaints(true))
  }

  dispatch(filterBaseStationsUp())
  dispatch(filterSitesUp())
}

const processElement = (dispatch, label, loader, projectLoader, indexer, activeProjectId) => {
  const skip = process.env[`REACT_APP_SKIP_${label.replace(' ', '_').toUpperCase()}`]?.toLowerCase()
  if (skip === 'true' || skip === '1') {
    dispatch(addStage({ name: `${label}`, stage: STAGE_KEYS.LOADING }))
    dispatch(stageCompleted(`${label}`))
    return Promise.resolve()
  }
  dispatch(addStage({ name: `${label}`, stage: STAGE_KEYS.LOADING }))
  return dispatch(loader())
    .then(async () => {
      if (activeProjectId && projectLoader) {
        dispatch(stageLoaded(`${label}`))
        dispatch(addStage({ name: `Project ${label}`, stage: STAGE_KEYS.LOADING }))
        await dispatch(projectLoader(activeProjectId))
        dispatch(stageCompleted(`Project ${label}`))
      }
    })
    .catch((err) => {
      console.error(err)
      dispatch(stageAborted(`${label}`))
    })
    .then(() => {
      if (indexer) {
        dispatch(addStage({ name: `${label}`, stage: STAGE_KEYS.FILTERING }))
        return dispatch(indexer(false, true))
      }
    })
    .then((count) => {
      dispatch(stageFiltered(`${label}`))
      dispatch(stagePayload({ stageName: `${label}`, payloadValue: count }))
    })
    .then(() => {
      dispatch(stageCompleted(`${label}`))
    })
}

const initCoverage = () => async (dispatch, getState) => {
  const list = await dispatch(getCoverage())
  const ready = (list?.payload?.data || []).filter(({ status }) => status === READY_TO_USE)
    .map((item) => ({ ...item, isDefault: true }))
  const projectId = activeProjectId(getState())
  if (projectId) {
    const listP = await dispatch(getCoverage(projectId))
    const readyP = (listP?.payload?.data || []).filter(({ status }) => status === READY_TO_USE)
    ready.push(...readyP)
  }
  await dispatch(setCoverage(ready))
}

export const initNetwork = (force) => async (dispatch, getState) => {
  const state = getState()
  const projectId = activeProjectId(state)
  const lastLoadedProject = selectLastLoadedProject(state)
  if (projectId !== lastLoadedProject || force) {
    const userAccess = selectUserAccess(state)
    await dispatch(setLoading(true))
    await dispatch(getConfig())
    await Promise.all([
      processElement(dispatch, 'Sites', getSites, getProjectSites, filterSites, projectId),
      processElement(dispatch, 'RBS', getBaseStations, getProjectBaseStations, filterBaseStations, projectId),
      processElement(dispatch, 'Sectors', getSectors, getProjectSectors, filterSectors, projectId),
      userAccess[COMPLAINTS] && processElement(dispatch, 'Complaints', getComplaints, null, filterComplaints),
      processElement(dispatch, 'Vector Maps', initVectorMaps, null, vectorMapCount),
      processElement(dispatch, 'Raster Maps', initRasterMaps, null, rasterMapCount),
      userAccess[COVERAGE] && processElement(dispatch, 'Coverage', initCoverage, null, null),
      processElement(dispatch, 'Zones', loadGeoZones, null, selectZoneCount),
      projectId && dispatch(loadProjectJobs(projectId)),
    ])
    // Do additional filtering when all network elements are loaded
    dispatch(filterBaseStationsUp())
    dispatch(filterSitesUp())
    if (userAccess[BUSINESS_CASES]) {
      await processElement(dispatch, 'Business Cases', loadDefaultBCData, loadAllBCData, bcCount, projectId ?? '_')
      await dispatch(prepareSiteNames)
    }
    setTimeout(() => dispatch(clearStages()), HIDE_INIT_LOG)
    dispatch(setLastLoadedProject(projectId))
    // Restore Calculate for approve
    dispatch(setLoading(false))
    const isMyProject = selectIsMyProject(state)
    if (isMyProject) {
      dispatch(updateCalculateForApprove)
    }
  }
}

export const getSiteTargetCoverage = (siteId) => async (dispatch, getState) => {
  const state = getState()
  const siteFull = selectSitesFull(state)
  const siteIdx = siteFull?.findIndexById(siteId)
  const siteFields = selectSiteFields(state)
  const siteStatusIdx = findIndex(siteFields, SITE_STATUS_FIELD)
  const site = siteFull?.getList()[siteIdx]
  if (!site || site[siteStatusIdx] === STATUS_REMOVED) {
    return {}
  }
  const projectId = activeProjectId(state)
  const result = (await dispatch(siteTargetCoverage({
    siteId,
    activeProjectId: projectId,
  }))).payload
  const sectorsFull = selectSectorsFull(state)
  const fields = selectSectorFields(state)
  const nameIdx = findIndex(fields, SECTOR_NAME_FIELD)
  const statusIdx = findIndex(fields, SECTOR_STATUS_FIELD)
  for (const key of Object.keys(result)) {
    const geometry = result[key]
    const properties = result[`${key}_properties`]
    const sectorIdx = sectorsFull?.findIndexById(key)
    const sector = sectorsFull?.getList()[sectorIdx]
    if (!sector || sector[statusIdx] === STATUS_REMOVED) {
      delete result[key]
    } else {
      result[key] = {
        geometry,
        properties,
        hash: hash(geometry),
        label: sector?.[nameIdx],
      }
    }
  }
  return result
}

export const updateSectorTargetCoverage = (siteId, sectorId, polygon, properties) => (dispatch, getState) => {
  const state = getState()
  const projectId = activeProjectId(state)
  return dispatch(wrapByEventLog({
    type: EVENT_TYPE.targetCoverage,
    details: `Site = ${siteId}; Sector = ${sectorId}`,
    action: () => dispatch(sectorTargetCoverage({
      siteId,
      sectorId,
      activeProjectId: projectId,
      polygon,
      properties,
    })),
  }))
}

export const locateItem = (path) => (dispatch, getState) => {
  const node = findNode(getRoot(getState()), path)
  let { lat, lng } = node
  if (node?.id?.startsWith(ID_NETWORK_RBS)) { // обработка RBS
    const state = getState()
    const fieldsRBS = selectBaseStationFields(state)
    const siteIdIdx = findIndex(fieldsRBS, RBS_SITE_FIELD)
    const siteId = node.item[siteIdIdx]
    const siteFull = selectSitesFull(state)
    const siteIdx = siteFull?.findIndexById(siteId)
    const site = siteFull?.getList()[siteIdx]
    if (!site) {
      return
    }
    const fieldsSite = selectSiteFields(state)
    const latIdx = findIndex(fieldsSite, SITE_LAT_FIELD)
    const lngIdx = findIndex(fieldsSite, SITE_LNG_FIELD)
    lat = site[latIdx]
    lng = site[lngIdx]
  }
  if (lat && lng) {
    dispatch(setBounce({ lat, lng }))
  }
}

export const menuItemClick = (path, key) => (dispatch, getState) => {
  switch (key) {
    case 'show': {
      dispatch(treeItemSelect(path))
      return
    }
    case 'hide': {
      dispatch(treeItemUnselect(path))
      return
    }
    case 'create-site': {
      dispatch(createSiteMode(true))
      return
    }
    default: {
      const [ cmd, param ] = key.split(DIVIDER)
      if (cmd === 'group') {
        const node = findNode(getRoot(getState()), path)
        if (node.id === ID_NETWORK_SITES) {
          dispatch(setSitesGroupBy(param))
          dispatch(saveSettings({ groupBy: { sites: param } }))
        } else if (node.id === ID_NETWORK_SECTORS) {
          dispatch(setSectorsGroupBy(param))
          dispatch(saveSettings({ groupBy: { sectors: param } }))
        } else if (node.id === ID_NETWORK_COMPLAINTS) {
          dispatch(setComplaintsGroupBy(param))
          dispatch(saveSettings({ groupBy: { complaints: param } }))
        } else if (node.id === ID_NETWORK_RBS) {
          dispatch(setBaseStationsGroupBy(param))
          dispatch(saveSettings({ groupBy: { baseStations: param } }))
        }
      } else if (cmd === COVERAGE_REPORT_KEY) {
        dispatch(requestCoverageReport())
      }
      return undefined
    }
  }
}

const addEllipsis = (items, table) => {
  if (table?.length > MAX_LEGEND_ITEMS) {
    items.push({ color: 'transparent', text: `(limited to ${MAX_LEGEND_ITEMS} records)` })
  }
  return items
}

export const legendDiscreteItemValue = (value, { fieldType, fieldLabel }) => value === null
  ? ''
  : fieldType === 'boolean'
    ? `${fieldLabel} = ${value}`
    : value

const formDisplayLegend = (element, { spread, coloring, attribute } = {}, type, visibleTechs) => {
  if (spread === SPREAD_UNIQUE || ([ SPREAD_RANGE, SPREAD_DISCRETE ].includes(spread) && !attribute)) {
    return []
  }
  switch (spread) {
    case SPREAD_RANGE: {
      return addEllipsis((coloring?.table || [])
        .slice(0, MAX_LEGEND_ITEMS)
        .map(({ color, description }) => ({ color, text: description }))
        .reverse(), coloring?.table)
    }
    case SPREAD_DISCRETE: {
      return addEllipsis((coloring?.table || [])
        .slice(0, MAX_LEGEND_ITEMS)
        .map(({ color, value }) => ({ color, text: legendDiscreteItemValue(value, coloring) }))
        .reverse(), coloring?.table)
    }
    default: {
      return element === 'sites'
        ? [ { color: DEFAULT_COLORS.SITE, text: 'Default' } ]
        : Object.entries(SECTOR_COLORS)
          .filter(([ key ]) => extractTypeUnique(key) === type && (!visibleTechs || visibleTechs.includes(key)))
          .map(([ key, value ]) => ({ color: value, text: key }))
    }
  }
}

export const setExplorerGroupBy = (groupBy) => (dispatch) => {
  if (!groupBy) {
    return
  }
  for (const key of Object.keys(groupBy)) {
    switch (key) {
      case 'sites': {
        dispatch(setSitesGroupBy(groupBy[key]))
        break
      }
      case 'sectors': {
        dispatch(setSectorsGroupBy(groupBy[key]))
        break
      }
      case 'baseStations': {
        dispatch(setBaseStationsGroupBy(groupBy[key]))
        break
      }
      case 'complaints': {
        dispatch(setComplaintsGroupBy(groupBy[key]))
        break
      }
      default:
    }
  }
}

export const selectLegendNetwork = (state) => {
  let sectorsTechs = null
  let visibleTechs = null
  if (state.network.sectors.children && state.network.sectors.groupBy === SECTOR_TECH_FIELD) {
    visibleTechs = state.network.sectors.children.getList()
      .filter(({ state }) => state.selected)
    sectorsTechs = [ ...new Set(visibleTechs.map(({ name }) => extractTypeUnique(name))) ]
    visibleTechs = visibleTechs.map(({ name }) => name)
  }
  const result = {
    content: [],
  }
  const legend = state.settings.legend
  if (legend?.sites && state.network.sites.state?.selected) {
    result.content.push({
      title: state.network.sites.name,
      list: formDisplayLegend('sites', state.settings.display.sites),
    })
  }
  for (const type of SECTOR_TYPES_UNIQUE) {
    if (legend?.[type] && state.network.sectors.state?.selected && (!sectorsTechs || sectorsTechs.includes(type))) {
      result.content.push({
        title: type,
        list: formDisplayLegend('sectors', state.settings.display[type], type, visibleTechs),
      })
    }
  }
  if (legend?.complaints && state.network.complaints.state?.selected) {
    result.content.push({
      title: 'Complaints',
      list: state.settings?.complaintsGrouping
        ? [
            { color: '#F18017', text: 'Group over 1000 complaints' },
            { color: '#F0C20C', text: 'Group under 1000 complaints' },
            { color: '#6ECC39', text: 'Group under 100 complaints' },
            { color: '#62F60E', text: 'Single complaint' },
          ]
        : formDisplayLegend('complaints', state.settings?.display?.complaints),
    })
  }
  return result
}

export const reloadNetworkElements = (res) => async (dispatch, getState) => {
  const state = getState()
  const { sites, rbses, sectors, projectId, sites_, rbses_, sectors_ } = disassemblyBundle(res)
  const currentProjectId = activeProjectId(state)
  let error = 0

  if (projectId === currentProjectId) {
    const isSites_ = Array.isArray(sites_) && sites_.length > 0
    const isSites = Array.isArray(sites) && sites.length > 0
    if (isSites_ || isSites) {
      if (isSites_) {
        const res = await dispatch(requestSites({ projectId: '_', sites: sites_ }))
        if (res.error) {
          error++
        }
      }
      if (isSites) {
        const res = await dispatch(requestSites({ projectId, sites }))
        if (res.error) {
          error++
        }
      }
      // dispatch(filterSites(true))
    }

    const isRBSes_ = Array.isArray(rbses_) && rbses_.length > 0
    const isRBSes = Array.isArray(rbses) && rbses.length > 0
    if (isRBSes || isRBSes_) {
      if (isRBSes_) {
        const res = await dispatch(requestRBSes({ projectId: '_', rbses: rbses_ }))
        if (res.error) {
          error++
        }
      }
      if (isRBSes) {
        const res = await dispatch(requestRBSes({ projectId: projectId, rbses }))
        if (res.error) {
          error++
        }
      }
      // dispatch(filterBaseStations(true))
    }

    const isSectors_ = Array.isArray(sectors_) && sectors_.length > 0
    const isSectors = Array.isArray(sectors) && sectors.length > 0
    if (isSectors_ || isSectors) {
      if (isSectors_) {
        const res = await dispatch(requestSectors({ projectId: '_', sectors: sectors_ }))
        if (res.error) {
          error++
        }
      }
      if (isSectors) {
        const res = await dispatch(requestSectors({ projectId: projectId, sectors }))
        if (res.error) {
          error++
        }
      }
      // dispatch(filterSectors(true))
    }
  }
  if (error) {
    // TODO: Помилки при перезавантаженні даних проєкту, повідомити про необхідність перезавантажити увесь проєкт
    console.error('Error(s) reload data:', error)
  }
}

export const getNetworkItemsNotAvailableInAtoll = (sites) => async (dispatch, getState) => {
  const state = getState()
  const projectId = activeProjectId(state)
  const result = {
    sites: [],
    sectors: [],
  }

  const sitesData = []
  let sitesInfo = await dispatch(requestSites(({ projectId: '_', sites })))
  const sitesFields = sitesInfo.payload?.fields
  sitesData.push(...(sitesInfo.payload?.data || []))
  sitesInfo = await dispatch(requestSites(({ projectId, sites })))
  sitesData.push(...(sitesInfo.payload?.data || []))
  if (sitesData?.length === 0) {
    return {
      error: 'No network items to check sync status',
    }
  }
  const siteSyncStatusFieldIdx = findIndex(sitesFields, SITE_SYNC_ATOLL_STATUS_FIELD)
  const [ siteIdFieldIdx, siteNameFieldIdx ] = selectSiteFldIdx(state)
  sitesData.forEach((site) => {
    if (site[siteSyncStatusFieldIdx] === SYNC_ATOLL_STATUS_NEVER) {
      result.sites.push({
        id: site[siteIdFieldIdx],
        name: site[siteNameFieldIdx],
      })
    }
  })

  const sectors = []
  const fullSectors = selectSectorsFull(state)
  const [ sectorIdFieldIdx, sectorNameFieldIdx,,,,,,,, sectorRbsIdFieldIdx ] = selectSectorFldIdx(state)
  sites.forEach(({ siteId }) => {
    const sectorsRange = fullSectors.findRangeByValue(siteId, SECTOR_SITE_FIELD)
    sectorsRange?.forEach((index) => {
      const sector = fullSectors.getList()[index]
      sectors.push({
        siteId,
        rbsId: sector[sectorRbsIdFieldIdx],
        sectorId: sector[sectorIdFieldIdx],
      })
    })
  })

  const sectorsData = []
  let sectorsInfo = await dispatch(requestSectors(({ projectId: '_', sectors })))
  const sectorsFields = sectorsInfo.payload?.fields
  sectorsData.push(...(sectorsInfo.payload?.data || []))
  sectorsInfo = await dispatch(requestSectors(({ projectId, sectors })))
  sectorsData.push(...(sectorsInfo.payload?.data || []))
  const sectorSyncStatusFieldIdx = findIndex(sectorsFields, SECTOR_SYNC_ATOLL_STATUS_FIELD)
  sectorsData.forEach((sector) => {
    if (sector[sectorSyncStatusFieldIdx] === SYNC_ATOLL_STATUS_NEVER) {
      result.sectors.push({
        id: sector[sectorIdFieldIdx],
        name: sector[sectorNameFieldIdx],
      })
    }
  })

  return result.sites.length + result.sectors.length > 0 ? result : null
}

export const formCoverageBySignalReport = (data) => (dispatch, getState) => {
  const currentState = getState()
  const zones = currentState.geo.zones.children[INDEX_ZONE_FOCUS].children
  const zoneByName = zones.reduce((agg, zone) => {
    const poly = toPolygon(zone.zone, 3)
    simplify(poly, { tolerance: 0.0001, highQuality: true, mutate: true })
    agg[zone.name] = poly
    return agg
  }, {})
  // const complaintsState = selectComplaintsState(currentState)
  // const isAllSelected = complaintsState.selected && !complaintsState.partial
  const fields = selectComplaintFields(currentState)
  const typeFieldIdx = findIndex(fields, COMPLAINT_TYPE_P_FIELD)
  const latFieldIdx = findIndex(fields, COMPLAINT_LAT_FIELD)
  const lngFieldIdx = findIndex(fields, COMPLAINT_LNG_FIELD)
  const list = selectFilteredComplaints(currentState)
  list.forEach((item) => {
    const type = item[typeFieldIdx]
    // if (isAllSelected || item?.state?.selected) {
    data.forEach((zoneSignalData) => {
      const name = zoneSignalData.zoneName
      const zone = zoneByName[name]
      if (zone) {
        const lat = item[latFieldIdx]
        const lng = item[lngFieldIdx]
        const res = booleanPointInPolygon(point([ lng, lat ]), zone)
        if (res) {
          if (type === 'DATA') {
            zoneSignalData.dataComplaints = (zoneSignalData.dataComplaints || 0) + 1
          } else if (type === 'VOICE') {
            zoneSignalData.voiceComplaints = (zoneSignalData.voiceComplaints || 0) + 1
          } else if (type === 'OTHER') {
            zoneSignalData.dataVoiceComplaints = (zoneSignalData.dataVoiceComplaints || 0) + 1
          }
        }
      }
    })
    // }
  })
  const headers = [
    'Zone', 'Technology', 'Legend', 'Zone Surface (km²)', 'Surface (km²)', '% of Covered Area', '% Focus Zone',
    'Data Complaints', 'Voice Complaints', 'Other Complaints',
  ]
  const coverage = data.map((zoneData) => [
    zoneData.zoneName,
    zoneData.mapTech,
    zoneData.mapName,
    zoneData.zoneArea,
    zoneData.signalArea,
    zoneData.signalCovered,
    zoneData.zoneCovered,
    zoneData.dataComplaints,
    zoneData.voiceComplaints,
    zoneData.dataVoiceComplaints,
  ])
  if (coverage?.length > 0) {
    tableToExcel(headers, coverage, alwaysTrue, 'Coverage by Signal level', 'Coverage by Signal.xlsx')
  } else {
    const message = 'Coverage by Signal Level report is empty'
    window.dispatchEvent(new ErrorEvent('resterror', { message }))
  }
  return data.length
}

export const formCoverageBySectorReport = (data) => () => {
  const headers = [
    'Zone', 'Technology', 'Legend', 'Zone Surface (km²)', 'Surface (km²)', '% of Covered Area', '% Focus Zone',
  ]
  const coverage = data.map((zoneData) => [
    zoneData.zoneName,
    zoneData.mapTech,
    zoneData.sectorName,
    zoneData.zoneArea,
    zoneData.sectorArea,
    zoneData.sectorCovered,
    zoneData.zoneCovered,
  ])

  if (coverage?.length > 0) {
    tableToExcel(headers, coverage, alwaysTrue, 'Coverage by Sectors', 'Coverage by Sectors.xlsx')
  } else {
    const message = 'Coverage by Sectors report is empty'
    window.dispatchEvent(new ErrorEvent('resterror', { message }))
  }
  return data.length
}

export const formCoverageByPointListReport = (data) => () => {
  const headers = [
    '№', 'Address', 'Latitude', 'Longitude', 'Site Name', 'Sector Name', 'Band', 'RBS Latitude', 'RBS Longitude',
    'RBS Address', 'LAC', 'CID', 'dX', 'dY', 'Period', 'Top',
    'Weight', 'Weight Azimuth', 'Weight Distance', 'Weight Tech',
  ]
  const coverage = data.map((row) => [
    row.number,
    row.address,
    row.latitude,
    row.longitude,
    row.siteName,
    row.sectorName,
    row.tech,
    row.sectorLatitude,
    row.sectorLongitude,
    row.siteAddress,
    row.sectorLac,
    row.sectorCid,
    row.sectorDistanceToSiteH,
    row.sectorDistanceToSiteV,
    row.period,
    row.top,
    row.w,
    row.w_azimuth,
    row.w_distance,
    row.w_tech,
  ])

  const ALLOW_EMPTY_REPORT = true
  if (coverage?.length > 0 || ALLOW_EMPTY_REPORT) {
    tableToExcel(headers, coverage, alwaysTrue, 'Coverage by Point List', 'Coverage by Point List.xlsx')
  } else {
    const message = 'Coverage by Point List report is empty'
    window.dispatchEvent(new ErrorEvent('resterror', { message }))
  }
  return data.length
}

export const formCoverageByZoneListReport = (data) => () => {
  const headers = []
  const coverage = data.map(() => [])

  if (coverage?.length > 0) {
    tableToExcel(headers, coverage, alwaysTrue, 'Coverage by Zone List', 'Coverage by Zone List.xlsx')
  } else {
    const message = 'Coverage by Zone List report is empty'
    window.dispatchEvent(new ErrorEvent('resterror', { message }))
  }
  return data.length
}

export const requestCoverageReport = () => (dispatch, getState) => {
  const currentState = getState()
  const zones = selectFocusZones(currentState.geo)
  if (zones.length > 0) {
    const projectId = activeProjectId(currentState)
    const selectedMapIds = selectSelectedCoverage(currentState)
    const zoneIds = zones.map(({ uuid }) => uuid)
    if (selectedMapIds?.length > 0) {
      const mapIds = selectedMapIds.map(({ id }) => id)
      return dispatch(doRequestAsyncReport(REPORT_COVERAGE_BY_SIGNAL, { projectId, zoneIds, mapIds }))
      // return dispatch(getCoverageBySignal({ activeProjectId: projectId, zoneIds, mapIds }))
    } else {
      const mapIds = selectDefaultCoverageMapIds(currentState)
      return dispatch(doRequestAsyncReport(REPORT_COVERAGE_BY_SECTOR, { projectId, zoneIds, mapIds }))
      // return dispatch(getCoverageBySectors({ activeProjectId: projectId, zoneIds, mapIds }))
    }
  }
}

const monthFirstDay = (date) => `${date.toISOString().slice(0, 8)}01`

export const setHistoricalComplaints = (data) => async (dispatch, getState) => {
  const state = getState()
  if (JSON.stringify(state.network.complaints.historical) !== JSON.stringify(data)) {
    if (data) {
      const { dateFrom, dateTo } = data
      dispatch(setComplaintsHistorical({ dateFrom, dateTo }))
      const projectId = activeProjectId(state) ?? '_'
      console.info('Loading Historical Complaints:', dateFrom, ' -- ', dateTo)
      await dispatch(getComplaintsForPeriod({
        projectId,
        fromDate: monthFirstDay(dateFrom),
        toDate: monthFirstDay(dateTo),
      }))
    } else {
      dispatch(setComplaintsHistorical(null))
      console.info('Loading Current Complaints')
      await dispatch(getComplaints())
    }
    dispatch(filterComplaints(true))
  }
}

export const initNetworkTemplates = () => (dispatch) => dispatch(getNetworkTemplates())

export const resetAllFilters = (props) => async (dispatch, getState) => {
  const state = getState()
  const hasFilters = selectHasNetworkFilters(state)
  const deepFiltrationUp = selectSettingsDeepFiltrationUp(state)
  const deepFiltrationDown = selectSettingsDeepFiltrationDown(state)
  document.body.classList.add('waiting')
  setTimeout(async () => {
    // Delete filtering zone
    if (state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_FILTERING)?.zone?.length > 0) {
      await dispatch(deleteZones(ID_GEO_ZONES_FILTERING))
      await dispatch(dropZone(ID_GEO_ZONES_FILTERING))
    }
    // Delete computation zone
    if (state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_COMPUTATION)?.zone?.length > 0) {
      await dispatch(deleteZones(ID_GEO_ZONES_COMPUTATION))
      await dispatch(dropZone(ID_GEO_ZONES_COMPUTATION))
    }
    // Remove all filters
    await dispatch(resetFiltersInAllTables())
    // Disable deep filtration
    if (deepFiltrationUp || deepFiltrationDown) {
      const deepFiltration = {
        up: false,
        down: false,
      }
      await dispatch(saveSettings({ deepFiltration }))
    }
    if (props ? props.filterNetworkRequired : (hasFilters || deepFiltrationUp || deepFiltrationDown)) {
      await dispatch(filterNetwork())
    }
    document.body.classList.remove('waiting')
  }, 1)
}

export default networkSlice.reducer

export * from './mutations'
