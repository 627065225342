const keys = {
  ID: 'id',
  TYPE: 'type',
  PROJECT_NAME: 'name',
  CHECK: 'check',
  DESCRIPTION: 'description',
  OWNER: 'owner',
  DATE: 'createdAt',
  NAME: 'name',
  LOGIN: 'login',
  ROLES: 'roles',
  IDENTIFIER: 'identifier',
  STATUS: 'status',
  EXECUTE_DETAILS: 'executeDetails',
  ROLE: 'role',
  CHECKBOX: 'checkbox',
  OWNER_ID: 'owner.id',
  OWNER_NAME: 'owner.name',
  PROPERTIES: 'properties',
  SOURCE_NAME: 'sourceName',
  LAST_SYNC_DATE: 'createdAt',
  FINISHED_AT: 'finishedAt',
  SYNCHRONIZATION_PERIOD: 'Period',
  SYNCHRONIZATION_FROM: 'fromDate',
  SYNCHRONIZATION_TO: 'toDate',
  SHARED: 'shared',
  GRANTS: 'grants',
}

export default keys
