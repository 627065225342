import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useBoolean } from '@fluentui/react-hooks'
import { Modal, Spinner, SpinnerSize } from '@fluentui/react'
import Handsontable from 'handsontable'
import {
  loadAllBCData, selectBCFields, selectBCList, selectBCFldIdx,
  filterAllBCData, addFilters, resetFilters,
  selectLoading,
  clearCreateBCData,
  selectBCFull,
  approve,
  reject,
  importBusinessCases,
  doCreateBusinessCases,
  doDeleteBusinessCases,
  doCopyGroupBusinessCase,
  doCheckCorrespondence,
  selectCheckingCorrespondence,
  selectSynchronizing,
  BCNeighborsInfo,
  selectBCRedraw,
  selectDefaultBC,
  selectIsBCFieldEditable,
} from '../../features/bc/bcSlice'
import {
  BC_NAME_FIELD,
  BC_GROUP_NAME_FIELD,
  BC_SITE_FIELD,
  BC_ID_FIELD,
  REQUIRED_STATUS_FOR_APPROVE,
  REQUIRED_STATUS_FOR_REJECT,
  REQUIRED_STATUS_FOR_DISABLE_DELETE,
  BC_SELECTED_FIELD,
  BC_NEIGHBORS_FIELD,
  getCalcMethodClassName,
  CALC_METHOD_MANUAL_NEIGHBORS,
} from '../../features/bc/constants'
import { selectUser, selectUserAccess } from '../../features/login/loginSlice'
import { userIsFPA } from '../../features/login/utils'
import { EVENT_TYPE, saveEventLog, wrapByEventLog } from '../../features/eventLog/eventLogSlice'
import {
  ensureUserProject, activeProject, selectIsMyProject, activeProjectId,
} from '../../features/projects/projectsSlice'
import Table from '../common/Table'
import { DATA_TYPES } from '../../constants/common'
import { useConfirm } from '../common/Confirm'
import { initColumnBC } from '../Tables/utils'
import HeaderContext from '../../layout/context/HeaderContext'
import { setPanel } from '../../features/panel/panelSlice'
import { findIndex } from '../../features/network/indexing'
import { APPROVE_BUSINESS_CASES, BUSINESS_CASES } from '../../constants/access'
import CreateBusinessCase, { convertValue } from './CreateBusinessCase'
// import BCSummaryDialog from './BCSummaryDialog'
import CopyGroupBCDialog from './CopyGroupBCDialog'

import './BusinessCases.css'
import { copyToClipboard } from './utils'

const BusinessCasesComponent = ({ setBusinessCasesActions }) => {
  const { vectorMapId: selectedGroupCase } = useParams()
  const dispatch = useDispatch()
  const refHot = useRef()
  const location = useLocation()
  const history = useHistory()
  const { renderConfirm, msg, confirm } = useConfirm()

  const projectId = useSelector(activeProjectId)

  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const user = useSelector(selectUser)
  const project = useSelector(activeProject)
  const isMyProject = useSelector(selectIsMyProject)
  const isReadOnly = useMemo(() => {
    return !userAccess[BUSINESS_CASES] || !isMyProject
  }, [ userAccess, isMyProject ])

  const loading = useSelector(selectLoading)
  const checkingCorrespondence = useSelector(selectCheckingCorrespondence)
  const full = useSelector(selectBCFull)
  const data = useSelector(selectBCList)
  const defaultBCs = useSelector(selectDefaultBC)
  const fields = useSelector(selectBCFields)
  const redraw = useSelector(selectBCRedraw)
  const [ idFieldIdx ] = useSelector(selectBCFldIdx, shallowEqual)
  const [ isCopyOpen, { setTrue: showCopy, setFalse: hideCopy } ] = useBoolean(false)

  const [ selectedRows, setSelectedRows ] = useState(null)

  const synchronizing = useSelector(selectSynchronizing)

  const isBCFieldEditableFunction = useSelector(selectIsBCFieldEditable)
  const isBCFieldEditable = useMemo(() => {
    return isBCFieldEditableFunction(fields, data?.getList())
  }, [ fields, data, isBCFieldEditableFunction ])

  const [
    bcIdColIndex, bcNameColIndex, gcIdColIndex, bcGroupNameColIndex,
    statusColIndex,,,, calcMethodColIndex, selectedColIndex,
    bcInstanceIdColIndex,
  ] = useSelector(selectBCFldIdx, shallowEqual)

  const unselectAllRows = useCallback((table) => {
    const totalRows = table.countRows()
    const newValues = []
    for (let index = 0; index < totalRows; index++) {
      newValues.push([ index, selectedColIndex, false ])
    }
    table.setDataAtCell(newValues)
    setSelectedRows(null)
  }, [ selectedColIndex ])

  // доустановка настроек колонок таблицы
  const columns = useMemo(() => (initColumnBC(fields)), [ fields ])

  useEffect(() => {
    const table = refHot.current?.hotInstance
    if (table && full) {
      unselectAllRows(table)
    }
  }, [ unselectAllRows, full ])

  const selectedGroupBusinessCaseId = useMemo(() => {
    const ids = selectedRows && selectedRows.reduce((agg, row) => {
      if (!agg.includes(row.groupCaseId)) {
        agg.push(row.groupCaseId)
      }
      return agg
    }, [])
    return ids?.length === 1 ? ids[0] : null
  }, [ selectedRows ])

  const getContextMenuDisabled = useCallback((selectedRows, selectedGroupBusinessCaseId) => {
    let disabledRemoveActions
    let disabledApproveActions
    let disabledRejectActions

    const disabledSingleActions = !selectedRows || selectedRows.length !== 1

    if (!selectedRows || isReadOnly) {
      disabledRemoveActions = true
    } else {
      disabledRemoveActions = selectedRows.findIndex((row) => {
        const bcIndex = full.findIndexById(row.id)
        const bc = full.getList()[bcIndex]
        const status = bc[statusColIndex]
        const bcId = bc[bcIdColIndex]
        return (synchronizing && synchronizing.bcIds?.[bcId]) ||
          REQUIRED_STATUS_FOR_DISABLE_DELETE.includes(status)
      }) !== -1
    }

    if (!userAccess[APPROVE_BUSINESS_CASES] || !selectedRows || !project) {
      disabledApproveActions = true
      disabledRejectActions = true
    } else {
      let approveAllowed = true
      let rejectAllowed = true
      selectedRows.every((row) => {
        const bcIndex = full.findIndexById(row.id)
        const name = full.getList()[bcIndex][bcNameColIndex]
        const status = full.getList()[bcIndex][statusColIndex]
        if (!REQUIRED_STATUS_FOR_APPROVE.includes(status)) {
          approveAllowed = false
        }
        if (!REQUIRED_STATUS_FOR_REJECT.includes(status) || defaultBCs?.includes(name)) {
          rejectAllowed = false
        }
        return approveAllowed || rejectAllowed
      })
      disabledApproveActions = !approveAllowed
      disabledRejectActions = !rejectAllowed
    }

    const selectedGroupIds = selectedRows
      ? selectedRows.reduce((agg, row) => {
        if (!agg.includes(row.groupCaseId)) {
          agg.push(row.groupCaseId)
        }
        return agg
      }, [])
      : []

    return {
      openGroup: selectedGroupIds.length > 1,
      copyGroup: selectedGroupBusinessCaseId === null,
      caseSummary: disabledSingleActions,
      deleteBC: disabledRemoveActions,
      approveBC: disabledApproveActions,
      rejectBC: disabledRejectActions,
    }
  }, [
    isReadOnly, full, statusColIndex, userAccess, project,
    bcIdColIndex, synchronizing, defaultBCs, bcNameColIndex,
  ])

  const contextMenuDisabled = useMemo((...props) => (
    getContextMenuDisabled(selectedRows, selectedGroupBusinessCaseId)
  ), [ getContextMenuDisabled, selectedRows, selectedGroupBusinessCaseId ])

  const onContextMenu = useCallback((contextMenu) => {
    const table = refHot.current?.hotInstance
    if (table) {
      const [ [ row ] ] = table.getSelected() || []

      if (row === -1) {
        return
      }

      const col = table?.toVisualColumn(selectedColIndex)
      const selected = table.getDataAtCell(row, col)
      if (!selected) {
        const physRow = table?.toPhysicalRow(row)
        const list = data.getList()
        const id = list[physRow][bcIdColIndex]
        const groupCaseId = list[physRow][gcIdColIndex]
        const newSelectedRows = [
          {
            groupCaseId,
            id,
            instanceId: list[physRow][bcInstanceIdColIndex] || -100,
          },
        ]
        dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Add selection of BC with id ${id}`))
        setSelectedRows(newSelectedRows)

        const dis = getContextMenuDisabled(newSelectedRows, groupCaseId)
        contextMenu.forEach((item) => {
          if (dis[item.key] !== undefined) {
            item.disabled = dis[item.key]
          }
        })
        // Update table
        const changes = selectedRows?.map(({ id }) => {
          const idx = data.findIndexById(id)
          dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Remove selection of BC with id ${id}`))
          return [ table.toVisualRow(idx), col, false ]
        }) || []
        changes.push([ row, col, true ])
        table.setDataAtCell(changes)
      }
    }
  }, [
    selectedColIndex, data, selectedRows,
    bcIdColIndex, bcInstanceIdColIndex, gcIdColIndex,
    dispatch, getContextMenuDisabled,
  ])

  useEffect(() => {
    const table = refHot.current?.hotInstance
    if (!table || !data || loading) {
      return
    }
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('bc')) {
      const id = queryParams.get('bc')
      const idx = data.findIndexById(id)
      if (idx === undefined) {
        return
      }

      // Remove BC is from URL
      queryParams.delete('bc')
      history.replace({
        search: queryParams.toString(),
      })

      const list = data.getList()
      const groupCaseId = list[idx][gcIdColIndex]
      const instanceId = list[idx][bcInstanceIdColIndex] || -100
      const newSelectedRows = [
        {
          groupCaseId,
          id,
          instanceId,
        },
      ]
      dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Add selection of BC with id ${id}`))
      setSelectedRows(newSelectedRows)
      // Update grid
      const col = table?.toVisualColumn(selectedColIndex)
      const row = table.toVisualRow(idx)
      const changes = selectedRows?.map(({ id }) => {
        const idx = data.findIndexById(id)
        dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Remove selection of BC with id ${id}`))
        return [ table.toVisualRow(idx), col, false ]
      }) || []
      changes.push([ row, col, true ])
      table.setDataAtCell(changes)
      // Scroll to row
      const showRow = Math.min(row + 5, table.countRows() - 1)
      table.selectCell(showRow, 0)
      table.deselectCell()
      table.selectRows(row)
    }
  }, [
    data, selectedColIndex, gcIdColIndex, bcInstanceIdColIndex,
    dispatch, history, location.pathname, location.search, selectedRows, loading,
  ])

  const contextMenuHidden = useCallback((name, selected) => {
    const selectedCells = selected?.reduce((agg, selection) => {
      if (selection[3] > selection[1]) {
        [ ...Array(selection[3] - selection[1] + 1).keys() ].forEach((i) => agg.cols.add(selection[1] + i))
      } else {
        agg.cols.add(selection[1])
      }
      if (selection[2] > selection[0]) {
        [ ...Array(selection[2] - selection[0] + 1).keys() ].forEach((i) => agg.rows.add(selection[0] + i))
      } else {
        agg.rows.add(selection[0])
      }
      return agg
    }, { cols: new Set(), rows: new Set() })
    let hideCopyNeighbours = true
    if (selectedCells.cols.size === 1 && selectedCells.rows.size === 1) {
      const fieldIdx = selectedCells.cols.values().next().value
      const table = refHot.current?.hotInstance
      const physCol = table?.toPhysicalColumn(fieldIdx)
      const id = columns[physCol].id
      hideCopyNeighbours = id !== BC_NEIGHBORS_FIELD
    }

    switch (name) {
      case 'openGroup':
      case 'copyGroup':
      case 'deleteBC':
        return isReadOnly
      case 'approveBC':
      case 'rejectBC':
        return !userAccess[APPROVE_BUSINESS_CASES] || !userIsFPA(user)
      case 'copy':
        return !hideCopyNeighbours
      case 'copyList':
      case 'copyJSON':
        return hideCopyNeighbours
      default:
        return false
    }
  }, [ isReadOnly, userAccess, user, columns ])

  const onPressOpenBC = useCallback(() => {
    if (selectedRows && selectedRows.length === 1) {
      // setSelectedBCId(selectedRows[0])
      // openBCSummary()
      dispatch(setPanel(`bc-summary#${JSON.stringify(selectedRows[0])}`))
    }
  }, [ dispatch, selectedRows /* , openBCSummary */ ])

  const groupByGroupCase = useCallback((collectManualNeighbors) => {
    return selectedRows.reduce((res, bcId) => {
      const bcIndex = full.findIndexById(bcId.id)
      const groupCaseId = full.getList()[bcIndex][gcIdColIndex]
      if (!res[groupCaseId]) {
        res[groupCaseId] = []
      }
      res[groupCaseId].push(bcId.id)
      if (collectManualNeighbors) {
        if (!res.manualNeighbors) {
          res.manualNeighbors = []
        }
        const calcMethod = full.getList()[bcIndex][calcMethodColIndex]
        if (calcMethod === CALC_METHOD_MANUAL_NEIGHBORS) {
          const name = full.getList()[bcIndex][bcNameColIndex]
          res.manualNeighbors.push(name)
        }
      }
      return res
    }, {})
  }, [ selectedRows, full, gcIdColIndex, bcNameColIndex, calcMethodColIndex ])

  const selectedBCNames = useCallback(() => {
    return selectedRows?.map((item) => {
      const bcIndex = full.findIndexById(item.id)
      const bcName = full.getList()[bcIndex][bcNameColIndex]
      const groupCaseName = full.getList()[bcIndex][bcGroupNameColIndex]
      return `${groupCaseName}/${bcName}`
    }, {})
  }, [ selectedRows, full, bcNameColIndex, bcGroupNameColIndex ])

  const onBusinessCasesUpdated = useCallback(async () => {
    await setSelectedRows(null)
    await dispatch(loadAllBCData())
  }, [ dispatch ])

  const doMultipleActions = useCallback(async (data, action) => {
    const gcIds = Object.keys(data)
    const result = await Promise.all(gcIds.map((groupCaseId) => {
      const businessCaseIds = data[groupCaseId]
      return dispatch(action({
        groupCaseId,
        businessCaseIds,
      }))
    }))
    const errorMessages = []
    result.forEach((res) => {
      if (res.error?.message) {
        errorMessages.push(res.error?.message)
      }
    })
    return errorMessages
  }, [ dispatch ])

  const doAndRefresh = useCallback(async (data, action) => {
    const errorMessages = await doMultipleActions(data, action)
    if (errorMessages.length > 0) {
      msg({ messages: errorMessages })
    }
    const gcIds = Object.keys(data)
    if (gcIds.length !== errorMessages.length) {
      // Not all request are failed, need to refresh
      onBusinessCasesUpdated()
    }
  }, [ doMultipleActions, msg, onBusinessCasesUpdated ])

  const onPressApprove = useCallback(() => {
    const { manualNeighbors, ...bcToApprove } = groupByGroupCase(true)

    const doApprove = () => {
      doAndRefresh(bcToApprove, approve)
    }

    if (manualNeighbors.length > 0) {
      const message = manualNeighbors.length > 1
        ? `There are ${manualNeighbors.length} business cases that are calculated by "MANUAL_NEIGHBORS" method`
        : `The business case "${manualNeighbors[0]}" is calculated by "MANUAL_NEIGHBORS" method`
      confirm(
        doApprove,
        {
          title: 'Confirmation',
          messages: [ message ],
          textYesBtn: 'Continue',
          textNoBtn: 'Cancel',
        },
      )
    } else {
      doApprove()
    }
  }, [ groupByGroupCase, doAndRefresh, confirm ])

  const onPressReject = useCallback(() => {
    const bcToReject = groupByGroupCase()
    doAndRefresh(bcToReject, reject)
  }, [ groupByGroupCase, doAndRefresh ])

  const onPressRemove = useCallback(() => {
    if (!selectedRows) {
      return true
    }
    const doRemove = () => {
      const bcToRemove = groupByGroupCase()
      doAndRefresh(bcToRemove, doDeleteBusinessCases)
    }
    confirm(
      doRemove,
      {
        title: 'Confirmation',
        messages: [ 'Selected BCs will be deleted from your project!' ],
        textYesBtn: 'Continue',
        textNoBtn: 'Cancel',
      },
    )
  }, [ selectedRows, confirm, groupByGroupCase, doAndRefresh ])

  const onPressCreateBC = useCallback(async () => {
    if (!project) {
      const id = await dispatch(ensureUserProject())
      if (id === '_') {
        return
      }
    }
    history.push(`/${projectId}/business-cases/_`)
    await dispatch(clearCreateBCData())
  }, [ dispatch, project, history, projectId ])

  const onPressUpdateBC = useCallback(async () => {
    if (selectedRows && selectedRows.length > 0) {
      const selectedBCId = selectedRows[0].id
      const bcIndex = full.findIndexById(selectedBCId)
      const groupCaseId = full.getList()[bcIndex][gcIdColIndex]
      history.push(`/${projectId}/business-cases/${groupCaseId}`)
      dispatch(setPanel(null))
    }
  }, [
    selectedRows, gcIdColIndex, full, dispatch, history, projectId,
  ])

  const onPressCopyGBC = useCallback(() => {
    showCopy()
  }, [ showCopy ])

  const onCloseCreateBC = useCallback(() => {
    history.push(`/${projectId}/business-cases`)
  }, [ history, projectId ])

  const bcNeighborsRenderer = useCallback(function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)
    if (value) {
      if (value instanceof BCNeighborsInfo) {
        const { count, title } = value
        td.innerHTML = `<div class="relative"><span style="margin-left: 25px" title="${title}">${count}<span></div>`
        td.classList.add('with-title')
      } else {
        const neighbors = new Set()
        Object.keys(value).forEach((tech) => {
          Object.keys(value[tech]).forEach((sector) => {
            if (value[tech][sector].length > 0) {
              value[tech][sector].forEach(neighbors.add, neighbors)
            }
          })
        })
        const count = neighbors.size
        td.innerHTML = `<div class="relative"><span style="margin-left: 25px">${count}<span></div>`
        td.classList.add('with-title')
      }
    } else {
      td.classList.remove('with-title')
      td.removeAttribute('title')
    }
  }, [])

  const cells = useCallback((row, col) => {
    const cellProperties = {}
    // const table = refHot.current?.hotInstance
    const physCol = col // table?.toPhysicalColumn(col)
    const physRow = row // table?.toPhysicalRow(row)
    const id = fields?.[physCol]?.id
    const list = data?.getList()
    if (id === BC_NEIGHBORS_FIELD) {
      cellProperties.renderer = bcNeighborsRenderer
    }

    const editable = isBCFieldEditable(physCol, physRow)
    cellProperties.readOnly = !editable

    cellProperties.className = (cellProperties.className ? cellProperties.className + ' ' : '') +
      getCalcMethodClassName(list?.[physRow]?.[calcMethodColIndex])

    return cellProperties
  }, [
    fields, data, calcMethodColIndex,
    bcNeighborsRenderer, isBCFieldEditable,
  ])

  const checkCorrespondence = useCallback(async () => {
    const activeProjectId = await dispatch(ensureUserProject())
    if (activeProjectId === '_') {
      return
    }
    const result = await dispatch(doCheckCorrespondence(activeProjectId))
    if (!result?.error) {
      onBusinessCasesUpdated()
    }
  }, [ dispatch, onBusinessCasesUpdated ])

  const logActionAndExecute = useCallback((name, action) => (props) => {
    const bcs = selectedBCNames()?.join(', ') || ''
    dispatch(saveEventLog(EVENT_TYPE.bcActions, `: ${name}${bcs ? ` [Selected BCs: ${bcs}]` : ''}`))
    action(props)
  }, [ selectedBCNames, dispatch ])

  const handleCopyGBC = useCallback(async (groupBusinessCaseId, newName) => {
    hideCopy()
    const result = await dispatch(doCopyGroupBusinessCase({ groupBusinessCaseId, newName }))
    if (!result?.error) {
      onBusinessCasesUpdated()
    }
  }, [ hideCopy, dispatch, onBusinessCasesUpdated ])

  const onInlineEdit = useCallback(async (row, field, _, value) => {
    const table = refHot.current?.hotInstance
    const physCol = field // table?.toPhysicalColumn(col)
    const physRow = table?.toPhysicalRow(row)
    const list = data?.getList()
    if (!list) {
      return
    }
    if (physCol === selectedColIndex) {
      const groupCaseId = list[physRow][gcIdColIndex]
      const id = list[physRow][bcIdColIndex]
      const instanceId = list[physRow][bcInstanceIdColIndex] || -100
      if (value) {
        if (selectedRows?.findIndex((item) => item.id === id) >= 0) {
          // Item already added
        } else {
          setSelectedRows([
            ...(selectedRows || []),
            {
              groupCaseId,
              id,
              instanceId,
            },
          ])
          dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Select BC with id ${id}`))
        }
      } else {
        if (selectedRows?.findIndex((item) => item.id === id) >= 0) {
          const newSelectedRows = selectedRows?.filter((item) => item.id !== id)
          setSelectedRows(newSelectedRows && newSelectedRows.length > 0 ? newSelectedRows : null)
          dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Unselect BC with id ${id}`))
        }
      }
    } else {
      const row = list[physRow]
      const gcId = row[gcIdColIndex]
      const id = row[bcIdColIndex]
      const type = columns[physCol].type
      value = convertValue(value, type)
      dispatch(doCreateBusinessCases({
        groupCaseId: gcId,
        businessCases: [ {
          [BC_ID_FIELD]: id,
          [columns[physCol].id]: value,
        } ],
      }))
      dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Modify BC with id ${id}, ${columns[physCol].id}: ${value}`))
    }
  }, [
    dispatch, columns, data,
    setSelectedRows, selectedRows,
    selectedColIndex, bcIdColIndex, gcIdColIndex, bcInstanceIdColIndex,
  ])

  const afterOnCellMouseUp = useCallback((event, coords, td) => {
    const { col, row } = coords
    const table = refHot.current?.hotInstance
    const physCol = table.toPhysicalColumn(col)
    if (table && row === -1 && physCol === selectedColIndex) {
      dispatch(saveEventLog(EVENT_TYPE.bcActions, ': Unselect all BC'))
      unselectAllRows(table)
    }
  }, [ dispatch, selectedColIndex, unselectAllRows ])

  const onImport = useCallback(async (cols, rows) => {
    const imported = await dispatch(wrapByEventLog({
      type: EVENT_TYPE.bcImport,
      action: async () => {
        const nameColumn = cols.findIndex((col) => col?.id === BC_NAME_FIELD)
        if (nameColumn < 0) {
          msg({
            messages: [ 'Mandatory column "BC Name" not found in dataset being imported' ],
          })
          return false
        }
        const siteNameColumn = cols.findIndex((col) => col?.id === BC_SITE_FIELD)
        if (siteNameColumn < 0) {
          msg({
            messages: [ 'Mandatory column "Site" not found in dataset being imported' ],
          })
          return false
        }
        const gcNameColumn = cols.findIndex((col) => col?.id === BC_GROUP_NAME_FIELD)
        if (gcNameColumn < 0) {
          msg({
            messages: [ 'Mandatory column "Group Case Name" not found in dataset being imported' ],
          })
          return false
        }

        const result = await dispatch(importBusinessCases({ cols, rows }))
        if (!result) {
          return false
        }
        const messages = []

        if (result?.error) {
          messages.push(result.error)
        }

        const results = []

        if (result.updated > 0) {
          results.push(`${result.updated} Business Case${result.updated > 1 ? 's were' : ' was'} updated`)
        }
        if (result.created > 0) {
          results.push(`${result.created} Business Case${result.created > 1 ? 's were' : ' was'} created`)
        }

        messages.push(
          `${results.length ? results.join(' and ') : 'No changes were made to Business Cases'} by this import operation.`,
        )
        if (result.noSite > 0) {
          messages.push(
            `Error importing ${result.noSite} Business Case${result.noSite > 1 ? 's' : ''}: no element found for given Site Name.`,
            '(You can not create new Sites by importing data to Sectors table.)',
          )
        }
        if (result.duplicateSite > 0) {
          messages.push(
            `Error importing ${result.duplicateSite} Business Case${result.duplicateSite > 1 ? 's' : ''}: duplicate Site Name.`,
          )
        }
        if (result.duplicateBCName > 0) {
          messages.push(
            `Error importing ${result.duplicateBCName} Business Case${result.duplicateBCName > 1 ? 's' : ''}: duplicate Business Case Name.`,
          )
        }
        if (result.duplicateNeighbors > 0) {
          messages.push(
            `Error importing ${result.duplicateNeighbors} Business Case${result.duplicateNeighbors > 1 ? 's' : ''}: duplicate sector neighbors.`,
          )
        }
        if (result.serverError > 0) {
          messages.push(
            `Error importing ${result.serverError} Business Case${result.serverError > 1 ? 's' : ''}: ${result.serverErrorMessage || 'server operation failed.'}`,
          )
        }
        if (result.noMandatoryValue > 0) {
          messages.push(
            `Error importing ${result.noMandatoryValue} Business Case${result.noMandatoryValue > 1 ? 's' : ''}: missed or invalid value in mandatory column.`,
          )
        }
        if (result.incorrectDateFormat > 0) {
          messages.push(
            `Error importing ${result.incorrectDateFormat} Business Case${result.incorrectDateFormat > 1 ? 's' : ''}: date format not supported.`,
          )
        }
        if (result.incorrectStartCapexDate > 0) {
          messages.push(
            `Error importing ${result.incorrectStartCapexDate} Business Case${result.incorrectStartCapexDate > 1 ? 's' : ''}: Start Capex Date can't be in the past.`,
          )
        }
        if (result.incorrectStartRevenueDate > 0) {
          messages.push(
            `Error importing ${result.incorrectStartRevenueDate} Business Case${result.incorrectStartRevenueDate > 1 ? 's' : ''}: Start Revenue Date can't be before Start Capex Date.`,
          )
        }
        if (result.incorrectValue.length > 0) {
          const ends = result.incorrectValue.length > 1 ? 's' : ''
          const names = result.incorrectValue.filter((n) => n)
          messages.push(
            `Error importing ${result.incorrectValue.length} Business Case${ends}: ${names.join(', ')} contain${names.length > 0 ? '' : 's'} incorrect values.`,
          )
        }
        if (result.tooManyItemsValue.length > 0) {
          const ends = result.tooManyItemsValue.length > 1 ? 's' : ''
          const names = result.tooManyItemsValue.filter((n) => n)
          messages.push(
            `Error importing ${result.tooManyItemsValue.length} Business Case${ends}: ${names.join(', ')} contain${names.length > 0 ? '' : 's'} too many sectors.`,
          )
        }
        if (result.noItemValue.length > 0) {
          const ends = result.noItemValue.length > 1 ? 's' : ''
          const names = result.noItemValue.filter((n) => n)
          messages.push(
            `Error importing ${result.noItemValue.length} Business Case${ends}: ${names.join(', ')} must contain at minimum one valid sector.`,
          )
        }
        if (result.modificationNotAllowed > 0) {
          messages.push(
            `Error importing ${result.modificationNotAllowed} Business Case${result.modificationNotAllowed > 1 ? 's' : ''}: modification is not allowed.`,
          )
        }
        if (result.cantCreateBCG.length > 0) {
          const ends = result.cantCreateBCG.length > 1 ? 's' : ''
          messages.push(
            `Error importing ${result.cantCreateBCG.length} Business Case${ends}: can't create new Group Case${ends} during import.`,
          )
        }
        msg({ messages })

        if (result.updated || result.created) {
          await onBusinessCasesUpdated()
        }

        return messages
      },
      extractor: (result) => {
        return result ? result.join(' ') : 'No rows imported'
      },
    }))

    return imported && imported.length > 0
  }, [ dispatch, msg, onBusinessCasesUpdated ])

  const renderCheckingCorrespondence = () => {
    return (
      <Modal
        isBlocking={true}
        isOpen={checkingCorrespondence}
        containerClassName={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h4>{'Checking correspondance'}</h4>
        <Spinner
          size={SpinnerSize.large}
          className="centered"
        />
      </Modal>
    )
  }

  const onCallbackContextMenu = useCallback((key, selection, clickEvent, refHot) => {
    switch (key) {
      case 'openGroup':
        logActionAndExecute('Press update BC', onPressUpdateBC)()
        break
      case 'copyGroup':
        logActionAndExecute('Press copy Group BC', onPressCopyGBC)()
        break
      case 'caseSummary':
        logActionAndExecute('Open BC summary', onPressOpenBC)()
        break
      case 'deleteBC':
        logActionAndExecute('Remove BC', onPressRemove)()
        break
      case 'approveBC':
        logActionAndExecute('Approve BC', onPressApprove)()
        break
      case 'rejectBC':
        logActionAndExecute('Reject BC', onPressReject)()
        break
      case 'copyList':
      case 'copyJSON':
        copyToClipboard(refHot, selection, key === 'copyJSON')
        break
      default:
    }
  }, [
    logActionAndExecute,
    onPressApprove, onPressCopyGBC, onPressOpenBC, onPressReject, onPressRemove, onPressUpdateBC,
  ])

  const businessCasesActions = useMemo(() => ({
    create: logActionAndExecute('Open create BC', onPressCreateBC),
    createDisabled: !isMyProject || (selectedRows && selectedRows.length >= 1),
    createHidden: isReadOnly,
    correspondence: logActionAndExecute('Check correspondence', checkCorrespondence),
    correspondenceDisabled: checkingCorrespondence || isReadOnly,
    correspondenceHidden: !userAccess[BUSINESS_CASES],
  }), [
    isMyProject, selectedRows, userAccess, onPressCreateBC, checkingCorrespondence, isReadOnly, checkCorrespondence,
    logActionAndExecute,
  ])

  useEffect(() => {
    setBusinessCasesActions && setBusinessCasesActions(businessCasesActions)
  }, [ businessCasesActions, setBusinessCasesActions ])

  const ignoreWhenExport = useMemo(() => ([ findIndex(fields, BC_SELECTED_FIELD) ]), [ fields ])

  return (
    <>
    {selectedGroupCase
      ? (
          <CreateBusinessCase
            hidden={!selectedGroupCase}
            hideDialog={onCloseCreateBC}
            project={project}
            onBusinessCasesUpdated={onBusinessCasesUpdated}
            onImport={onImport}
          />
        )
      : (
    <>
      {loading
        ? (
            <Spinner className="centered" size={SpinnerSize.large} />
          )
        : data && columns && redraw >= 0
          ? (
              <Table
                data={data}
                columns={columns}
                idFieldIdx={idFieldIdx}
                refHot={refHot}
                dataType={DATA_TYPES.BUSINESS_CASES}
                onDblClick={onPressOpenBC}
                dataFiltering={filterAllBCData}
                addFilters={addFilters}
                resetFilters={resetFilters}
                cells={cells}
                onInlineEditCell={onInlineEdit}
                afterOnCellMouseUp={afterOnCellMouseUp}
                readOnly={isReadOnly}
                manualColumnMove
                onCallbackContextMenu={onCallbackContextMenu}
                onContextMenu={onContextMenu}
                contextMenuDisabled={contextMenuDisabled}
                contextMenuHidden={contextMenuHidden}
                disableHotKeyEvents={[ 'KeyH' ]}
                ignoreWhenExport={ignoreWhenExport}
              />
            )
          : null
      }
      <CopyGroupBCDialog
        isShow={isCopyOpen}
        onHide={hideCopy}
        onCreate={handleCopyGBC}
        groupBC={selectedGroupBusinessCaseId}
      />
      {renderCheckingCorrespondence()}
    </>
        )}
    {renderConfirm()}
  </>
  )
}

const BusinessCases = () => (
  <HeaderContext.Consumer>
    {({ setBusinessCasesActions }) => (
      <BusinessCasesComponent setBusinessCasesActions={setBusinessCasesActions} />
    )}
  </HeaderContext.Consumer>
)

export default BusinessCases
