import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { KEYS } from '../../components/Cabinet/constants'
import { generateNameProject } from '../../components/Cabinet/Project/utils'
import { selectUser, selectUserAccess } from '../login/loginSlice'
import { setLastLoadedProject } from '../network/networkSlice'
import { EDIT_PROJECTS } from '../../constants/access'
import { DATA_TYPES } from '../../constants/common'
import { selectBCFields, setBCList } from '../bc/bcSlice'
import { EVENT_TYPE, wrapByEventLog } from '../eventLog/eventLogSlice'
import { saveAllZones } from '../geo/geoSlice'
import { selectNeedReloadToCreateProjectFromDefault } from '../compositeIndex/compositeIndexSlice'

const NEW_PROJECT = 'New project'

const initialState = {
  loading: false,
  name: 'Projects',
  list: [],
  active: null,
  activeProjectRemoved: false,
  activeProjectUnshared: false,
  confirmEnsureProject: null,
  taskLog: [],
  reload: 0,
}

const loading = (state) => {
  state.loading = true
}

const creation = (state) => {
  state.creation = true
}

const removal = (state) => {
  state.removal = true
}
const grantAccess = (state) => {
  state.grantAccess = true
}

const doLoadProjects = createAsyncThunk(
  'projects/getProjects',
  api.projects.load,
)

export const doLoadProject = createAsyncThunk(
  'projects/getProject',
  api.projects.loadProject,
)

export const doSaveProject = createAsyncThunk(
  'projects/saveProject',
  api.projects.update,
)

const doCopyProject = createAsyncThunk(
  'projects/copyProject',
  api.projects.copy,
)

const doCreateProject = createAsyncThunk(
  'projects/createProject',
  api.projects.create,
)

const doRemoveProject = createAsyncThunk(
  'projects/removeProject',
  api.projects.remove,
)

const doGrantAccessToProject = createAsyncThunk(
  'projects/grantAccessToProjects',
  api.projects.grantAccess,
)

const doGetGrantsToProject = createAsyncThunk(
  'projects/getGrantsToProject',
  api.projects.getProjectGrants,
)

const doRevokeAccessToProject = createAsyncThunk(
  'projects/revokeAccessToProject',
  api.projects.revokeAccess,
)

const doRevokesAccessToProject = createAsyncThunk(
  'projects/revokesAccessToProjects',
  api.projects.revokesAccess,
)

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjectList: (state, action) => {
      state.list = action.payload
    },
    setActiveProject: (state, action) => {
      state.active = action.payload || null
      const projectId = state.active?.id || '_'
      if (window.location.href.indexOf(`/${projectId}/`) < 0) {
        window.history.replaceState(null, '', `/${projectId}/map`)
      }
    },
    setConfirmEnsureProject: (state, action) => {
      state.confirmEnsureProject = action.payload
    },
    forceProjectReload: (state) => {
      state.reload += 1
    },
    setActiveProjectRemoved: (state, action) => {
      state.activeProjectRemoved = action
    },
    setActiveProjectUnshared: (state, action) => {
      state.activeProjectUnshared = action
    },
  },
  extraReducers: (builder) => builder
    .addCase(doLoadProjects.pending, loading)
    .addCase(doLoadProjects.fulfilled, (state, action) => {
      state.loading = false
      state.ready = true
      state.list = action.payload
    })
    .addCase(doLoadProjects.rejected, (state, action) => {
      state.loading = false
      console.error('doLoadProjects', action)
    })

    .addCase(doLoadProject.pending, loading)
    .addCase(doLoadProject.fulfilled, (state, action) => {
      state.loading = false
      state.ready = true
      state.active = action.payload
      const projectId = state.active?.id || '_'
      if (window.location.href.indexOf(`/${projectId}/`) < 0) {
        const keepPage = action.meta?.arg?.keepPage
        if (keepPage) {
          const path = window.location.pathname
          const defIdIndex = path.lastIndexOf(`${projectId}/`)
          const page = defIdIndex >= 0 ? path.substring(defIdIndex + 2) : path.split('/').pop()
          window.history.replaceState(null, '', `/${projectId}/${page}`)
        } else {
          window.history.replaceState(null, '', `/${projectId}/map`)
        }
      }
    })
    .addCase(doLoadProject.rejected, (state, action) => {
      state.loading = false
      console.error('doLoadProject', action)
    })

    .addCase(doCreateProject.pending, creation)
    .addCase(doCreateProject.fulfilled, (state) => {
      state.creating = false
    })
    .addCase(doCreateProject.rejected, console.error)

    .addCase(doRemoveProject.pending, removal)
    .addCase(doRemoveProject.fulfilled, (state) => {
      state.removal = false
    })
    .addCase(doRemoveProject.rejected, console.error)

    .addCase(doGrantAccessToProject.pending, grantAccess)
    .addCase(doGrantAccessToProject.fulfilled, (state) => {
      state.grantAccess = false
    })
    .addCase(doGrantAccessToProject.rejected, console.error)
    .addCase(doRevokesAccessToProject.rejected, console.error)
    .addCase(doCopyProject.pending, creation)
    .addCase(doCopyProject.fulfilled, (state) => {
      state.creating = false
    })
    .addCase(doCopyProject.rejected, console.error),
})

export const {
  setConfirmEnsureProject, forceProjectReload, setActiveProjectRemoved, setActiveProjectUnshared,
} = projectsSlice.actions

// export const readyProjects = (state) => state.projects.ready
export const selectProjects = (state) => state.projects.list
export const loadingProjects = (state) => state.projects?.loading
export const activeProject = (state) => state.projects.active
export const selectIsActiveProjectRemoved = (state) => state.projects.activeProjectRemoved
export const selectIsActiveProjectUnshared = (state) => state.projects.activeProjectUnshared
export const activeProjectId = (state) => state.projects.active?.[KEYS.ID]
export const activeProjectName = (state) => state.projects.active?.[KEYS.DESCRIPTION]

export const selectConfirmEnsureProject = (state) => state.projects.confirmEnsureProject
export const selectForceProjectReload = (state) => state.projects.reload

export const selectIsMyProject = (state) => state.projects.active?.owner?.id === state.login.user?.id
export const selectIsDefaultProject = (state) => state.projects.active === null
export const selectIsSharedProject = (state) => !selectIsMyProject(state) && !selectIsDefaultProject(state)

const projectsLoading = (state) => state.projects.loading

export const loadProjects = () => (dispatch, getState) => {
  const currentState = getState()
  if (!projectsLoading(currentState)) {
    return dispatch(doLoadProjects())
  }
}

export const loadProject = (projectId, keepPage) => async (dispatch, getState) => {
  if (projectId !== '_') {
    const currentState = getState()
    if (!projectsLoading(currentState)) {
      return dispatch(wrapByEventLog({
        type: EVENT_TYPE.projects,
        details: `Load; ID = ${projectId}`,
        action: () => dispatch(doLoadProject({ projectId, keepPage })),
      }))
    }
  }
}

export const loadProjectSettings = (projectId) => async (dispatch, getState) => {
  const { payload } = await dispatch(doLoadProject({ projectId }))
  return payload.properties
}

export const saveProject = (project) => (dispatch) => {
  const projectId = project[KEYS.ID]
  const data = {
    [KEYS.NAME]: project[KEYS.NAME],
    [KEYS.DESCRIPTION]: project[KEYS.DESCRIPTION],
    [KEYS.PROPERTIES]: {},
  }
  return dispatch(wrapByEventLog({
    type: EVENT_TYPE.projects,
    details: `Save; Name = ${project[KEYS.DESCRIPTION]}`,
    action: () => dispatch(doSaveProject({ projectId, data })),
  }))
}

export const copyProject = (projectId, name, description) => async (dispatch) => {
  const result = await dispatch(wrapByEventLog({
    type: EVENT_TYPE.projects,
    details: `Copy; Source ID = ${projectId}; Target Name = ${description}`,
    action: () => dispatch(doCopyProject({ projectId, name, description })),
    extractor: (result) => `[Copy; ID = ${result?.payload?.[KEYS.ID]}]`,
  }))
  await dispatch(loadProjects())
  return result?.payload?.[KEYS.ID]
}

export const createProject = (project) => async (dispatch) => {
  const result = await dispatch(wrapByEventLog({
    type: EVENT_TYPE.projects,
    details: `Create; Name = ${project[KEYS.DESCRIPTION]}`,
    action: () => dispatch(doCreateProject(project)),
    extractor: (result) => `[Create; ID = ${result?.payload?.[KEYS.ID]}]`,
  }))
  await dispatch(loadProjects())
  return result?.payload?.[KEYS.ID]
}

const confirmEnsureProject = (project) => async (dispatch, getState) => {
  await new Promise((resolve) => {
    dispatch(setConfirmEnsureProject({ ...project, resolve, confirmed: false }))
  })
  const state = getState()
  const {
    [KEYS.PROJECT_NAME]: name,
    [KEYS.DESCRIPTION]: description,
    confirmed,
  } = state.projects.confirmEnsureProject
  dispatch(setConfirmEnsureProject(null))
  return {
    [KEYS.PROJECT_NAME]: confirmed ? name : project[KEYS.PROJECT_NAME],
    [KEYS.DESCRIPTION]: confirmed ? description : project[KEYS.DESCRIPTION],
    confirmed,
  }
}

export const ensureUserProject = ({ isManualCreate } = {}) => async (dispatch, getState) => {
  const state = getState()
  let id = activeProjectId(state)
  const user = selectUser(state)
  const userAccess = selectUserAccess(state)
  if (!id && userAccess[EDIT_PROJECTS]) {
    const projects = selectProjects(state)
    const newName = generateNameProject(user, projects) || NEW_PROJECT
    const { confirmed, ...project } = await dispatch(confirmEnsureProject({
      [KEYS.PROJECT_NAME]: newName,
      [KEYS.DESCRIPTION]: isManualCreate ? ' Created new project' : 'Automatically created new project',
    }))
    if (confirmed) {
      const created = await dispatch(wrapByEventLog({
        type: EVENT_TYPE.projects,
        details: `Create; Name = ${project[KEYS.DESCRIPTION]}`,
        action: () => dispatch(doCreateProject(project)),
        extractor: (result) => `[Create; ID = ${result?.payload?.[KEYS.ID]}]`,
      }))
      id = created.payload[KEYS.ID]
      dispatch(setLastLoadedProject(id))
      await dispatch(saveAllZones(id))
      const needReload = selectNeedReloadToCreateProjectFromDefault(state)
      if (needReload) {
        window.location.href = window.location.href.replace(/\/_\//, `/${id}/`)
      } else {
        dispatch(setBCList({ dataType: DATA_TYPES.BUSINESS_CASES, list: [], fields: selectBCFields(state) }))
        await dispatch(loadProject(id ?? '_', true))
      }
    }
  }
  return id ?? '_'
}

export const removeProject = (projects) => (dispatch) => dispatch(wrapByEventLog({
  type: EVENT_TYPE.projects,
  details: `Remove; Projects = ${projects.join(', ')}`,
  action: () => Promise.all(projects.map((projectId) => (dispatch(doRemoveProject(projectId))))),
}))

export const grantAccessToProjects = (projects, users) => (dispatch) => {
  if (Array.isArray(projects) && Array.isArray(users) && users.length > 0) {
    return dispatch(wrapByEventLog({
      type: EVENT_TYPE.projects,
      details: `Grant access; Projects = ${projects.join(', ')}, Users = ${users.join(', ')}`,
      action: () => Promise.all(projects.map((projectId) => dispatch(doGrantAccessToProject({ projectId, users })))),
    }))
  }
}

export const removeAccessToProject = (projectId, users) => (dispatch) => {
  if (projectId && Array.isArray(users) && users.length > 0) {
    return dispatch(wrapByEventLog({
      type: EVENT_TYPE.projects,
      details: `Remove access; Project = ${projectId}, Users = ${users.join(', ')}`,
      action: () => dispatch(doRevokesAccessToProject({ projectId, users })),
    }))
  }
}

export const getGrantsToProject = (project) => (dispatch) => {
  return dispatch(wrapByEventLog({
    type: EVENT_TYPE.projects,
    details: `Get grants to the project ${project}`,
    action: () => dispatch(doGetGrantsToProject(project)),
  }))
}

export const revokeAccessToProjects = (projects) => (dispatch) => dispatch(wrapByEventLog({
  type: EVENT_TYPE.projects,
  details: `Revoke access; Projects = ${projects.join(', ')}`,
  action: () => Promise.all(projects.map((projectId) => dispatch(doRevokeAccessToProject(projectId)))),
}))

export default projectsSlice.reducer
