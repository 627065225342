import React, { useCallback, useMemo } from 'react'
import Handsontable from 'handsontable'
import moment from 'moment'
import Table from '../common/Table'
import {
  BC_NAME_FIELD,
  BC_SITE_FIELD,
  BC_SELECTED_FIELD,
  REQUIRED_STATUS_FOR_DISABLE_ANY_CHANGES,
  BC_DATE_CAPEX_FIELD,
  BC_DATE_REVENUE_FIELD,
  BC_EX_STATUS_FIELD,
  BC_NEIGHBORS_FIELD,
  getCalcMethodClassName,
  BC_CALC_METHOD_FIELD,
  CALC_METHOD_MANUAL_NEIGHBORS,
  BC_SECTORS_FIELD,
  CALC_METHOD_NEW_SECTORS,
  BC_CASE_TYPE_FIELD,
  EXISTING_STATUS_ID,
} from '../../features/bc/constants'
import { DATA_TYPES } from '../../constants/common'
import { findIndex } from '../../features/network/indexing'
import { BCNeighborsInfo, addFilters, filterAllBCData, resetFilters } from '../../features/bc/bcSlice'

export const REQUIRED_COLOR = 'rgb(255 212 210) !important'

export const getMarkedCellKey = (id, fieldId) => `${id}|${fieldId}`

// eslint-disable-next-line react/display-name
const CreateBCTable = ({
  columns, data, onChange, onSelectAllChange, refHot, bcNames, siteNames,
  markedCells, onInlineEdit, onAddRows, onEditNeighbors, needToExport, onImport, idFieldIdx, exStatusFieldIdx,
  statusFieldIdx, calcMethodColIndex, onInlineEditRange, beforePaste,
  onCallbackContextMenu, onContextMenu, contextMenuDisabled, contextMenuHidden, contextMenuBusy,
  isBCFieldEditable, onEditSectors, onEditCalcMethod,
}) => {
  const importOptions = useMemo(() => ([ 'clip', 'xlsx' ]), [])
  const ignoreWhenExport = useMemo(() => ([
    findIndex(columns, BC_SELECTED_FIELD),
    findIndex(columns, BC_EX_STATUS_FIELD),
  ]), [ columns ])
  const capexDateFldIdx = useMemo(() => findIndex(columns, BC_DATE_CAPEX_FIELD), [ columns ])
  const calcMethodFldIdx = useMemo(() => findIndex(columns, BC_CALC_METHOD_FIELD), [ columns ])
  const selectedColumnIndex = useMemo(() => {
    return columns && columns.findIndex((col) => {
      return col.id === BC_SELECTED_FIELD
    })
  }, [ columns ])

  const markedRenderer = useCallback(function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments)
    if (markedCells && markedCells.size > 0) {
      const table = refHot.current?.hotInstance
      if (table) {
        const physRow = table.toPhysicalRow(row)
        const physCol = table.toPhysicalColumn(col)
        const fieldId = columns[physCol]?.id
        const id = data.getList()?.[physRow][idFieldIdx]
        if (markedCells.has(getMarkedCellKey(id, fieldId))) {
          td.style.backgroundColor = REQUIRED_COLOR
        }
      }
    }
  }, [ columns, data, idFieldIdx, markedCells, refHot ])

  const capexDateValidator = useCallback((query, resultCallback) => {
    const compare = moment(query).isSameOrAfter(moment().startOf('day'))
    resultCallback(compare)
  }, [])

  const revenueDateValidator = useCallback((row) => (query, resultCallback) => {
    const table = refHot.current?.hotInstance
    if (table && data) {
      const list = data?.getList()
      const capexDate = list?.[row]?.[capexDateFldIdx]
      const compare = moment(query).isSameOrAfter(moment(capexDate))
      resultCallback(compare)
      return
    }
    resultCallback(true)
  }, [ refHot, data, capexDateFldIdx ])

  const bcNeighborsRenderer = useCallback(function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)
    if (value) {
      let allowEdit = false
      if (value instanceof BCNeighborsInfo) {
        const { count, title, sectorsCount } = value
        td.innerHTML = `<div class="relative" style="display: inline" title="${title}">
          <span style="margin-left: 25px; margin-right: 5px;">${count}<span>
        </div>`
        td.classList.add('with-title')
        allowEdit = sectorsCount > 0
      } else {
        let sectorsCount = 0
        const neighbors = new Set()
        Object.keys(value).forEach((tech) => {
          Object.keys(value[tech])?.forEach((sector) => {
            if (value[tech][sector].length > 0) {
              value[tech][sector]?.forEach(neighbors.add, neighbors)
            }
            sectorsCount++
          })
        })
        const count = neighbors.size
        td.innerHTML = `<div class="relative" style="display: inline">
          <span style="margin-left: 25px; margin-right: 5px;">${count}<span>
        </div>`
        td.classList.add('with-title')

        allowEdit = sectorsCount > 0
      }

      if (allowEdit) {
        const table = refHot.current?.hotInstance
        const physRow = table?.toPhysicalRow(row)
        const bc = data.getList()?.[physRow]

        if (bc && bc[calcMethodFldIdx] === CALC_METHOD_MANUAL_NEIGHBORS &&
            !REQUIRED_STATUS_FOR_DISABLE_ANY_CHANGES.includes(bc[statusFieldIdx])) {
          const button = document.createElement('button')
          button.innerHTML = 'Edit'
          button.title = 'Edit neighbors'
          button.onclick = () => {
            onEditNeighbors(bc)
          }
          td.appendChild(button)
        }
      }
    } else {
      td.classList.remove('with-title')
      td.removeAttribute('title')
    }
  }, [ refHot, data, onEditNeighbors, calcMethodFldIdx, statusFieldIdx ])

  const bcSectorsRenderer = useCallback(function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)

    const table = refHot.current?.hotInstance
    const physRow = table?.toPhysicalRow(row)
    const bc = data.getList()?.[physRow]

    const allowEdit = bc && bc[calcMethodFldIdx] === CALC_METHOD_NEW_SECTORS &&
            !REQUIRED_STATUS_FOR_DISABLE_ANY_CHANGES.includes(bc[statusFieldIdx])
    if (allowEdit) {
      td.innerHTML = `
        <span style="margin-right: 7px; overflow: hidden; text-overflow: ellipsis;">${value || 'No sectors'}<span>
      `
      if (value) {
        td.classList.add('with-edit')
        const table = refHot.current?.hotInstance
        const physRow = table?.toPhysicalRow(row)
        const bc = data.getList()?.[physRow]

        const button = document.createElement('button')
        button.innerHTML = 'Edit'
        button.title = 'Edit sectors'
        button.onclick = () => {
          if (onEditSectors) {
            onEditSectors(bc)
          }
        }
        td.appendChild(button)
      }
    }
  }, [ data, onEditSectors, refHot, calcMethodFldIdx, statusFieldIdx ])

  const bcCalcMethodRenderer = useCallback(function (instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)

    td.innerHTML = ''
    const table = refHot.current?.hotInstance
    const physRow = table?.toPhysicalRow(row)
    const bc = data.getList()?.[physRow]

    const div = document.createElement('div')
    div.style = 'display: flex; flex-direction: row; align-items: center;'
    const span = document.createElement('span')
    span.style = 'margin-right: 7px; overflow: hidden; text-overflow: ellipsis;'
    span.innerHTML = value
    const button = document.createElement('button')
    button.innerHTML = 'Edit'
    button.title = 'Edit case type and calc method'
    button.onclick = () => {
      if (onEditCalcMethod) {
        onEditCalcMethod(bc)
      }
    }
    button.disabled = !bc || bc[exStatusFieldIdx] !== EXISTING_STATUS_ID.SELECTED
    div.appendChild(span)
    div.appendChild(button)
    td.appendChild(div)
  }, [ data, onEditCalcMethod, refHot, exStatusFieldIdx ])

  const cells = useCallback((row, col) => {
    const cellProperties = {}
    const physCol = col // table?.toPhysicalColumn(col)
    const physRow = row // table?.toPhysicalRow(row)

    const id = columns[physCol]?.id
    const type = columns[physCol]?.type
    let required = columns[physCol]?.required

    const list = data?.getList()
    const calcMethod = list?.[physRow]?.[calcMethodColIndex]

    const requiredInBcMethods = columns[physCol]?.requiredInBcMethods
    if (requiredInBcMethods && requiredInBcMethods.includes(calcMethod)) {
      required = true
    }

    if (id === BC_NAME_FIELD) {
      cellProperties.source = bcNames
    } else if (id === BC_SITE_FIELD) {
      cellProperties.source = siteNames
    } else if (id === BC_NEIGHBORS_FIELD) {
      cellProperties.renderer = bcNeighborsRenderer
    } else if (id === BC_SECTORS_FIELD) {
      cellProperties.renderer = bcSectorsRenderer
    } else if (id === BC_CALC_METHOD_FIELD || id === BC_CASE_TYPE_FIELD) {
      cellProperties.renderer = bcCalcMethodRenderer
    } else if ([ 'numeric', 'int', 'double' ].includes(type)) {
      cellProperties.renderer = markedRenderer
    }

    const editable = isBCFieldEditable(col, row)
    cellProperties.readOnly = !editable

    if (editable && (id === BC_NEIGHBORS_FIELD || id === BC_SECTORS_FIELD)) {
      cellProperties.readOnly = true
    }

    if (!cellProperties.readOnly && required) {
      const value = list?.[physRow]?.[physCol]
      if (value === undefined || value === null) {
        cellProperties.className = cellProperties.className
          ? cellProperties.className + ' required'
          : 'required'
      }
    }

    if (id === BC_DATE_CAPEX_FIELD) {
      cellProperties.validator = capexDateValidator
    } else if (id === BC_DATE_REVENUE_FIELD) {
      cellProperties.validator = revenueDateValidator(row)
    }

    cellProperties.className = (cellProperties.className ? cellProperties.className + ' ' : '') +
      getCalcMethodClassName(list?.[physRow]?.[calcMethodColIndex])

    if (id === BC_CALC_METHOD_FIELD || id === BC_CASE_TYPE_FIELD) {
      cellProperties.readOnly = true
    }

    return cellProperties
  }, [
    bcNames, calcMethodColIndex, columns, data, markedRenderer, siteNames,
    capexDateValidator, revenueDateValidator, bcNeighborsRenderer, isBCFieldEditable,
    bcSectorsRenderer, bcCalcMethodRenderer,
  ])

  const afterOnCellMouseUp = useCallback((event, coords, td) => {
    const { col, row } = coords
    const table = refHot?.current
    const physCol = table?.hotInstance?.toPhysicalColumn(col)
    if (table && physCol === selectedColumnIndex && row === -1) {
      const totalRows = table.hotInstance.countRows()
      const isSelected = table.hotInstance.getDataAtCell(1, col)
      const newValues = []
      for (let index = 0; index < totalRows; index++) {
        newValues.push([ index, col, !isSelected ])
      }
      table.hotInstance.setDataAtCell(newValues)
      if (onSelectAllChange) {
        onSelectAllChange(!isSelected)
      }
    }
  }, [ onSelectAllChange, refHot, selectedColumnIndex ])

  return data && columns && columns.length > 0
    ? (
      <Table
        refHot={refHot}
        id={'CreateBusinessCaseTable'}
        dataType={DATA_TYPES.CREATE_BUSINESS_CASES}
        data={data}
        columns={columns}
        onChange={onChange}
        currentRowClassName={''}
        afterOnCellMouseUp={afterOnCellMouseUp}
        onInlineEditCell={onInlineEdit}
        idFieldIdx={idFieldIdx}
        onInlineEditRange={onInlineEditRange}
        addRowsOptions={[ 1, 5, 10 ]}
        onAddRows={onAddRows}
        needToExport={needToExport}
        onImport={onImport}
        onImportOptions={importOptions}
        onExportOptions={importOptions}
        ignoreWhenExport={ignoreWhenExport}
        importItemId={DATA_TYPES.CREATE_BUSINESS_CASES}
        cells={cells}
        beforePaste={beforePaste}
        onCallbackContextMenu={onCallbackContextMenu}
        onContextMenu={onContextMenu}
        contextMenuDisabled={contextMenuDisabled}
        contextMenuHidden={contextMenuHidden}
        contextMenuBusy={contextMenuBusy}
        dataFiltering={filterAllBCData}
        addFilters={addFilters}
        resetFilters={resetFilters}
      />
      )
    : null
}

export default CreateBCTable
