import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react'
import { DefaultButton, PrimaryButton } from '../../common/Button'
import UserPickerList from '../UserPickerList'
import { KEYS } from '../constants'

import '../style.css'
import { getColumnIndexById } from '../Project/settings'
import { loadUsers, selectUsers } from '../../../features/users/usersSlice'

const MIN_WIDTH_DIALOG = 480

const UnshareProjectForm = ({ isShow, onHide, onOk, project }) => {
  const dispatch = useDispatch()
  const userPickerRef = useRef(null)

  useEffect(() => {
    if (isShow) {
      dispatch(loadUsers())
    }
  }, [ dispatch, isShow ])

  const [ selectedUsers, setSelectedUsers ] = useState([])

  const users = useSelector(selectUsers)

  const idColIdx = useMemo(() => getColumnIndexById(KEYS.ID), [])
  const nameColIdx = useMemo(() => getColumnIndexById(KEYS.DESCRIPTION), [])
  const grantsColIdx = useMemo(() => getColumnIndexById(KEYS.GRANTS), [])
  const projectId = useMemo(() => project?.[idColIdx], [ project, idColIdx ])
  const projectName = useMemo(() => project?.[nameColIdx], [ project, nameColIdx ])
  const projectUsers = useMemo(() => {
    const userIds = project?.[grantsColIdx]?.split(', ') ?? []
    return userIds.map((id) => {
      if (users) {
        return users.find((user) => user.id === id)
      } else {
        return {
          id,
          login: id,
          name: id,
        }
      }
    })
  }, [ project, grantsColIdx, users ])

  const handleOk = useCallback(() => {
    onOk(projectId, selectedUsers)
  }, [ projectId, selectedUsers, onOk ])

  return (
    <Dialog
      hidden={!isShow}
      onDismiss={onHide}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Removing users\' access to the project',
      }}
      minWidth={MIN_WIDTH_DIALOG}
      modalProps={{
        isBlocking: true,
        className: 'share-project',
      }}
    >
      {isShow && (
        <>
          <div>
            <p className="title">
              {`Project name: ${projectName}`}
            </p>
            <p className="title">
              Select users
            </p>
          </div>
          <UserPickerList
            userPickerRef={userPickerRef}
            setSelectedUsers={setSelectedUsers}
            users={projectUsers}
          />
          <DialogFooter>
            {onOk && (
              <PrimaryButton
                text="Unshare"
                onClick={handleOk}
                disabled={selectedUsers.length === 0}
              />
            )}
            <DefaultButton onClick={onHide} text="Cancel" />
          </DialogFooter>
        </>
      )}
    </Dialog>
  )
}

export default UnshareProjectForm
